import { useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";
import PaymentPolicy from "../../components/paymentInstruction/PaymentPolicy";

const PaymentInstructions = () => {
  const location = useLocation();
  const [policy, setPolicy] = useState(location.state?.policyId ?? "6");

  const handleOnChangePolicy = (event: any) => {
    setPolicy(event.target.value);
  };

  return (
    <div className="bg-[#F8F8F8] w-full">
      <Header searchTextChangeColor={true} />
      <div className="flex justify-center items-center pt-5 md:max-xl:pt-0">
        <div className="w-[85%] bg-white lg:max-xl:w-[92%] dvmax:dh:w-[90%] mb-10 rounded-[30px]">
          <div className="flex flex-col items-center justify-center h-full w-full p-10">
            <p className="text-[#122548] text-[70px] font-bold uppercase text-center">
              Hướng dẫn thanh toán VN PAY
            </p>
            <div className="pr-20 text-justify">
              <p className="text-[#122548] text-[25px] font-medium">
                Cổng thanh toán VN PAY là giải pháp thanh toán được phát triển
                bởi Công ty Cổ phần Giải pháp Thanh toán Việt Nam (VN PAY).
                Khách hàng sử dụng thẻ/tài khoản ngân hàng, tính năng QR Pay/VN
                PAY-QR được tích hợp trên ứng dụng Mobile Banking của ngân hàng
                hoặc Ví điện tử liên kết để thanh toán giao dịch và nhập mã giảm
                giá (nếu có).
              </p>
            </div>
            <div className="my-10 text-center">
              <p className="text-[#122548] text-[25px] font-medium">
                Quét mã VN PAY-QR trên 35+ Ứng dụng Mobile Banking và 15+ Ví
                điện tử liên kết
              </p>
              <img src="/policyGuide/policy_guide_11.png" alt="" />
              <img src="/policyGuide/policy_guide_12.png" alt="" />
            </div>
            <div className="my-10 text-center">
              <p className="text-[#122548] text-[25px] font-medium">
                Quét mã VN PAY-QR trên 35+ Ứng dụng Mobile Banking và 15+ Ví
                điện tử liên kết
              </p>
              <img src="/policyGuide/policy_guide_04.png" alt="" />
            </div>
            <div className="my-10 text-center">
              <p className="text-[#122548] text-[25px] font-medium">
                4 Thẻ thanh toán quốc tế
              </p>
              <img src="/policyGuide/policy_guide_01.png" alt="" />
            </div>
            <div className="my-10 text-center">
              <p className="text-[#122548] text-[25px] font-medium">
                Các phương thức thanh toán qua VN PAY
              </p>
              <img src="/policyGuide/policy_guide_06.png" alt="" />
            </div>
          </div>

          <PaymentPolicy />

          <div className="w-[80%] my-12 mx-auto text-center border-b-2"></div>
          <div className="flex flex-col items-center justify-center text-black">
            <h2 className="mb-12 text-2xl font-bold">KÊNH HỖ TRỢ VN PAY</h2>
            <ul>
              <li className="mb-2 flex justify-between">
                <span className="w-24 mr-8">Email</span>{" "}
                <span>hotro@vnpay.vn</span>
              </li>
              <li className="mb-2 flex justify-between">
                <span className="w-24 mr-8">Tổng đài </span>
                <div className="flex">
                  <p className="font-sans">*</p>
                  <p className="">3388 or 1900 55 55 77</p>
                </div>
              </li>

              <li className="mb-2 flex justify-between">
                <span className="w-24 mr-8">Zalo OA</span>
                <span>zalo.me/4134983655549474109</span>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-center ">
        <Footer />
      </div>
    </div>
  );
};

export default PaymentInstructions;
