import React from "react";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";
import backgroundImg from "../../assets/term/term_bg.png";

const PolicyPaymentPrivate = () => {
  return (
    <div className="">
      <div
        className="w-[100%] h-full"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute z-50 w-full">
          <Header />
        </div>

        <div className="w-[100%] h-full justify-center flex pt-48 pv:max-ph:pt-32 ph:max-md:pt-40 pb-16">
          <div className="h-[75vh] w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] rounded-[33px] justify-center items-center scroll-smooth bg-[#FFFFFF] bg-opacity-40 px-[16px] py-[36px] ">
            <div className="items-center justify-center flex">
              <h1 className="font-[800] pv:max-md:text-[25px] px-[50px] capitalize text-center text-[48px] text-[#142548]">
                Chính sách bảo mật thanh toán
              </h1>
            </div>
            <div
              className=" h-[80%] overflow-y-auto [&::-webkit-scrollbar]:mr-[100px] [&::-webkit-scrollbar]:w-2
      [&::-webkit-scrollbar-track]:rounded-full
[&::-webkit-scrollbar-track]:bg-[#9DCBFC]
[&::-webkit-scrollbar-thumb]:rounded-full
[&::-webkit-scrollbar-thumb]:bg-[#2A3E5E]"
            >
              <div
                className={`font-svn-gilroy w-full text-xl pv:max-md:text-[14px] font-medium leading-[normal] tracking-[0px] text-[#142548]  px-[16px] sm:px-[50px] `}
              >
                <span>

                  <div className="h-6" />
                  <p className="font-bold">I. Cam kết bảo mật</p>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    Hệ thống thẻ thanh toán hệ thống được cung cấp bởi các đối tác cổng thanh toán (“Đối Tác Cổng Thanh Toán”) đã được cấp phép hoạt động hợp pháp tại Việt Nam. Theo đó, các tiêu chuẩn bảo mật thanh toán thẻ tại Why Kids đảm bảo thủ theo các tiêu chuẩn bảo mật ngành.
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">
                    II. Quy định bảo mật
                  </p>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Chính sách giao dịch thanh toán bằng thẻ quốc tế và thẻ nội địa (ngân hàng internet) đảm bảo tuân thủ các tiêu chuẩn bảo mật của các Đối tác Tác vụ Thanh toán bao gồm:
                      </li>
                  <div className="h-3 " />
                      <ul className="pl-10 space-y-3">
                        <li>- Thông tin tài chính của Khách hàng sẽ được bảo vệ trong suốt quá trình giao dịch bằng giao thức SSL (mật Secure Sockets Layer).</li>
                        <li>- Chứng nhận tiêu chuẩn bảo mật dữ liệu thông tin thanh toán (PCI DSS) do Trustwave cung cấp.
                        </li>
                        <li>- Mật khẩu sử dụng một lần (OTP) được gửi qua SMS để đảm bảo việc truy cập tài khoản được xác thực.
                        </li>
                        <li>- Các nguyên tắc và quy định bảo mật thông tin trong ngành tài chính ngân hàng theo quy định của Ngân hàng nhà nước Việt Nam.
                        </li>
                        <li>- Chính sách bảo mật giao dịch trong thanh toán của Why Kids áp dụng với Khách hàng:
                        </li>
                        <li>-Why Kids không trực tiếp lưu trữ thông tin thẻ của khách hàng. Việc bảo mật thông tin thẻ thanh toán Khách hàng được thực hiện bởi Đối tác Cổng Thanh Toán đã được cấp phép.
                        </li>
                        <li>- Đối với thẻ quốc tế: thông tin thẻ thanh toán của Khách hàng có khả năng sử dụng khả năng thanh toán để xác nhận giao dịch xác nhận không được lưu trên hệ thống của Why Kids. Đối Tác Cổng Thanh Toán sẽ lưu trữ và bảo mật.
                        </li>
                        <li>- Đối với thẻ nội địa (ngân hàng internet), Why Kids chỉ lưu trữ mã đơn hàng, mã giao dịch và tên ngân hàng.
                        </li>
                        <li>- Why Kids cam kết chắc chắn thực hiện nghiêm túc các biện pháp bảo mật cần thiết cho mọi hoạt động thanh toán thực hiện trên sàn giao dịch thương mại điện tử Why Kids.
                        </li>
                      </ul>
                    </ul>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PolicyPaymentPrivate;
