import Footer from "../../components/Common/footer";
import backgroundImg from "../../assets/term/term_bg.png";
import Header from "../../components/Common/header/header";

function PolicyPage() {
  return (
    <div className="">
      <div
        className="w-[100%] h-full"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute z-50 w-full">
          <Header />
        </div>

        <div className="w-[100%] h-full justify-center flex pt-48 pb-16">
          <div className="h-[75vh] w-[95%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] rounded-[33px] justify-center items-center scroll-smooth bg-[#FFFFFF] bg-opacity-40 px-[16px] py-[36px] ">
            <div className="items-center justify-center flex">
              <h1 className="font-[800] px-[50px] text-[48px] text-[#142548]">
                Policy
              </h1>
            </div>
            <div
              className=" h-[90%] overflow-y-auto [&::-webkit-scrollbar]:mr-[100px] [&::-webkit-scrollbar]:w-2
          [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-[#9DCBFC]
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-[#2A3E5E]"
            >
              <p className="text-[48px] px-[50px]"></p>
              <div
                className={`font-svn-gilroy w-full text-xl font-medium leading-[normal] tracking-[0px] text-[#142548]  px-[16px] sm:px-[50px] `}
              >
                <span>
                  <p>
                    {"Welcome to the "}
                    <span className="font-bold">{"Whykids App"}</span>
                    {"and the Website "}
                    <span className="font-bold">whykids.edu.vn</span>
                    {", a product of "}
                    <span className="font-bold">
                      VÌ SAO JOINT STOCK COMPANY
                    </span>
                    . We recommend that you take the time to carefully read the
                    terms of use below. Your continued use of the app means you
                    agree to the rules outlined in this document.
                  </p>
                  <div className="h-6" />
                  <p>
                    If you do not agree with any part of these terms, please
                    stop using this app and website.
                  </p>
                  <div className="h-6" />
                  <p className="font-bold">I. Introduction to Whykids</p>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <li>
                      <span className="font-bold">{"Whykids "}</span>
                      Whykids is an educational app for children aged 3 to 7,
                      helping them develop knowledge through interactive lessons
                      and games.
                    </li>
                    <li>
                      The app was developed by VÌ SAO Joint Stock Company with
                      the aim of providing a fun, engaging, and safe learning
                      environment for children.
                    </li>
                    <li>
                      Users of the app include parents and children, who may or
                      may not be under parental supervision.
                    </li>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">
                    II. Rights and Responsibilities of the User
                  </p>
                  <div className="h-6" />
                  <span className="font-bold">1. Creating an Account and Using the App:</span>
                      
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Users (parents) need to provide accurate information
                        when creating an account. One account can manage up to
                        04 child learning profiles.
                      </li>
                      <li>
                        Users are responsible for maintaining the security of
                        their account information and password. The company is
                        not responsible for any losses resulting from
                        unauthorized use of your account.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                    <span className="font-bold">2. Legal Use:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Users agree not to use the app for illegal purposes, not
                        to interfere with the system, or to harm other users.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold">3. Compliance with Terms:</span>
                  
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        When using the app, you agree to comply with all rules
                        and policies set forth by Whykids and VÌ SAO Joint Stock
                        Company.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">
                    III. Rights and Responsibilities of the Company
                  </p>
                  <div className="h-6" />
                  <span className="font-bold">1. Information Security:</span>
                  
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Whykids is committed to protecting users' personal
                        information. All personal information will be kept
                        confidential and not shared with any third parties
                        without user consent, except as required by law
                        enforcement authorities.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold">2. Updating and Maintaining the App:</span>
                  
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Whykids reserves the right to change, modify, or suspend
                        app features without prior notice. We are committed to
                        updating the latest version to optimize user experience.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">IV. Fees and Service Packages</p>
                  <div className="h-6" />
                  <span className="font-bold">1. Service Packages:</span>                  
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Whykids offers service packages tailored to users'
                        needs, including (*):
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <p>
                    <span className="whitespace-pre-wrap">
                      {
                        "           + 3 Month Package: 499,000 VND/month (calculated from the date of registration and ending on the same day of the 3rd month following)."
                      }
                    </span>
                  </p>
                  <p>
                    <span className="whitespace-pre-wrap">
                      {
                        "           + 6 Month Package: 899,000 VND/month (calculated from the date of registration and ending on the same day of the 6th month following)."
                      }
                    </span>
                  </p>
                  <p>
                    <span className="whitespace-pre-wrap">
                      {
                        "           + 1 Year Package: 1,299,000 VND/year (calculated from the date of registration and ending on the same day of the next year)."
                      }
                    </span>
                  </p>
                  <p>
                    <span className="whitespace-pre-wrap">
                      {
                        "           + Lifetime Package: 2,299,000 VND (48 months) (calculated from the date of registration and ending on the same day of the 48th month following)."
                      }
                    </span>
                  </p>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <li>
                      Users can purchase service packages through Google Play,
                      the App Store, or on our official website at
                      whykids.edu.vn.
                    </li>
                    <li>
                      Note: During the development of the app, VÌ SAO Joint
                      Stock Company may upgrade and add new features, programs,
                      or paid curricula on the Whykids app. However, users are
                      not required to purchase or pay for these additional costs
                      if they do not wish to use the extended features, and this
                      will not affect the service package you are currently
                      using.
                    </li>
                    <li>
                      (*): Service prices may vary depending on the time of
                      registration and applicable promotional programs.
                    </li>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold">2. Payment Policy:</span>                  
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Payment can be made through secure online payment
                        platforms such as credit cards, e-wallets, or via the
                        App Store/Google Play.
                      </li>
                      <li>
                        After successful payment, the system will automatically
                        update your service package.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold">3. Refund Policy:</span>       
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Whykids does not refund completed transactions, except
                        in cases where system errors result in incorrect charges
                        or the system fails to provide the promised service.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">
                    V. Termination and Cancellation Policy
                  </p>
                  <div className="h-6" />
                  <span className="font-bold">1. Canceling Service Subscription:</span> 
                  
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Users can cancel their service subscription through the
                        App Store, Google Play, or by contacting Whykids support
                        at email: hotline@whycorp.vn.
                      </li>
                      <li>
                        Canceling the service will not affect access to the app
                        for the remaining duration of the paid package.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold">2. Termination of Service:</span> 
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Whykids reserves the right to suspend or terminate a
                        user’s account if a violation of the terms of use or
                        signs of fraud are detected.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">VI. Intellectual Property Rights</p>
                  <div className="h-6" />
                  <span className="font-bold">1. Content and Copyright:</span> 
                  <div className="h-6" />
                      <ul className="list-disc pl-10">
                        <li>
                          All content within the app, including text, images,
                          audio, video, and related software, is the
                          intellectual property of VÌ SAO Joint Stock Company.
                          Any acts of copying, distributing, or using content
                          without permission will be considered a copyright
                          infringement.
                        </li>
                      </ul>

                  <div className="h-6" />
                  <span className="font-bold">2. Trademark:</span>

                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        The trademarks and logos appearing in the Whykids app
                        are the property of VÌ SAO Joint Stock Company. Users
                        are not permitted to use any trademarks without the
                        Company’s consent.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">VII. Additional Terms</p>
                  <div className="h-6" />
                  <span className="font-bold">1. Amendments and Additions to Terms:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        Whykids reserves the right to change and update the
                        terms of use without prior notice. Users are responsible
                        for regularly reviewing the terms of use to ensure they
                        understand and comply with the latest changes.
                      </li>
                    </ul>
                  </ul>
                  <div className="h-6" />
                  <span className="font-bold">2. Contact Information:</span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <ul className="list-disc">
                      <li>
                        For any questions or complaints related to the use of
                        the app, please contact us directly via email at:
                        hotline@whycorp.vn or through our website:
                        whykids.edu.vn.
                      </li>
                    </ul>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default PolicyPage;
