import Footer from "../../components/Common/footer";
import backgroundImg from "../../assets/term/term_bg.png";
import Header from "../../components/Common/header/header";

function TermPage() {
  return (
    <div className="">
      <div
        className="w-[100%]  h-full"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute z-50 w-full">
          <Header />
        </div>

        <div className="w-[100%] h-full justify-center flex pt-48 pb-16">
          <div className="h-[75vh] w-[95%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] rounded-[33px] justify-center items-center scroll-smooth bg-[#FFFFFF] bg-opacity-40 px-[16px] py-[36px] ">
            <div className="items-center justify-center flex">
              <h1 className="font-[800] px-[50px] text-[48px] text-[#142548]">
                Term Of Use
              </h1>
            </div>
            <div
              className=" h-[90%] overflow-y-auto [&::-webkit-scrollbar]:mr-[100px] [&::-webkit-scrollbar]:w-2
          [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-[#9DCBFC]
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-[#2A3E5E]"
            >
              <p className="text-[48px] px-[50px]"></p>
              <div className="font-svn-gilroy w-full text-xl font-medium leading-[normal] tracking-[0px] text-[#142548] px-[50px]">
                <span>
                  <p className="font-bold">
                    I. Purpose of Collecting Information
                  </p>
                  <div className="h-6" />
                  <p>
                    Whykids collects users' personal information for the
                    following purposes:
                  </p>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <li>
                      To manage accounts and provide the best service to users.
                    </li>
                    <li>
                      To improve the quality of educational content and optimize
                      the learning experience for children.
                    </li>
                    <li>
                      To send notifications about updates, events, or new
                      promotional programs.
                    </li>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">
                    II. Scope of Information Collection
                  </p>
                  <div className="h-6" />
                  <p>We collect personal information including:</p>
                  <ul className="list-disc pl-10">
                    <li>Name, phone number, email address, and address.</li>
                    <li>
                      Information about children's learning history and
                      progress.
                    </li>
                    <li>
                      Information from mobile devices (operating system version,
                      device ID, etc.).
                    </li>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">III. Use of Information</p>
                  <div className="h-6" />
                  <p>Personal information is used to:</p>
                  <ul className="list-disc pl-10">
                    <li>Manage and provide services.</li>
                    <li>Improve user experience.</li>
                    <li>
                      Send notifications related to Whykids' services or events.
                    </li>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">IV. Information Retention Period</p>
                  <div className="h-6" />
                  <p>
                    Whykids retains personal information for as long as you use
                    your account. If the account is canceled, the information
                    will be retained for up to 30 additional days before being
                    permanently deleted.
                  </p>
                  <div className="h-6" />
                  <p className="font-bold">V. Personal Information Security</p>
                  <div className="h-6" />
                  <p>
                    Whykids is committed to protecting users' personal
                    information with advanced security measures. We do not share
                    personal information with third parties without your
                    consent, except as required by law enforcement authorities.
                  </p>
                  <div className="h-6" />
                  <p className="font-bold">VI. User Rights</p>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <li>
                      Users have the right to request access to, correct, or
                      delete their personal information by contacting us at
                      email: hotline@whycorp.vn.
                    </li>
                    <li>
                      You have the right to opt-out of receiving promotional
                      notifications from Whykids.
                    </li>
                  </ul>
                  <div className="h-6" />
                  <p className="font-bold">
                    VII. Complaint Resolution Mechanism
                  </p>
                  <div className="h-6" />
                  <p>
                    If you have any complaints regarding the privacy policy,
                    please contact us at email: hotline@whycorp.vn. We will
                    address your complaint within 7 business days.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default TermPage;
