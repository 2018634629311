import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import axiosConfigSchool from '../services/axiosConfigSchool';
import { getThumnailGameByKey } from '../services/lesson.service';

// Interface cho Referral Code
interface Game {
    ID: string;
    key: string;
    thumbnailUrl: string;
  }

// Interface cho Context
interface GameContextType {
  gamesData: Game[];
  addGame: (game: Game) => void;
  fetchGames: () => Promise<void>;
}

// Tạo Context
const GameContext = createContext<GameContextType | undefined>(undefined);


// Component provider để bao quanh các thành phần cần truy cập dữ liệu
export const GameProvider= ({ children }:{ children: ReactNode }) => {
  const [gamesData, setGamesData] = useState<Game[]>([]);

  const addGame = (game: Game) => {
    setGamesData((prevGames) => [...prevGames, game]);
  };

  const fetchGames = async () => {
    try {
      const data = await getThumnailGameByKey();

      
      // Trích xuất games từ response.data
      const games = data.data.map(({ID,key,thumbnailUrl}:Game)=>({ID,key,thumbnailUrl})); // Giả sử response.data có trường games
      setGamesData(games); // Cập nhật state với danh sách game
    } catch (error) {
      console.error("Error fetching games:", error);
    }
  };


  return (
    <GameContext.Provider value={{ gamesData, addGame,fetchGames }}>
      {children}
    </GameContext.Provider>
  );
};

// Hook để dễ sử dụng ReferralContext
export const useGameContext = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
};
