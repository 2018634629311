import React from "react";

interface MenuItem {
  key: string;
  label: string;
}

interface MenuLibraryProps {
  items: MenuItem[]; // Accept items as a prop
  onMenuClick: (key: string, index: number) => void;
  selectedKey: string;
}

const MenuLibrary: React.FC<MenuLibraryProps> = ({
  items,
  onMenuClick,
  selectedKey,
}) => {
  const handleClick = (key: string, index: number) => {
    console.log("Clicked index:", index); // Log the index
    onMenuClick(key, index); // Pass both key and index to the parent handler
  };

  return (
    <ul
      className="w-full bg-white rounded-[18px] lg:max-xl:rounded-[14px] font-semibold text-[20px] text-[#65AFFF] "
      style={{
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)", // A lighter shadow for a smoother appearance
      }}
    >
      {items.map((item, index) => (
        <li
          key={item.key}
          onClick={() => handleClick(item.key, index)} // Pass index as well
          className={`${
            index === 0
              ? "rounded-t-[18px] lg:max-xl:rounded-t-[14px]"
              : "rounded-[0px]"
          }
            ${
              index === items.length - 1
                ? "rounded-b-[18px] lg:max-xl:rounded-b-[14px]"
                : "rounded-[0px]"
            }
            cursor-pointer font-semibold text-lg lg:max-xl:text-[14px] lg:max-xl:px-[10px] lg:max-xl:py-[8px] border-b-[1px] py-4 px-7 border-gray-200 transition-colors duration-300 ${
              selectedKey === item.key
                ? "bg-[#D5EAFE]"
                : "bg-white hover:bg-[#D5EAFE]"
            }`}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export default MenuLibrary;
