// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhCWVZdmETDMpO7fBbtli8-phPOYPiM0A",
  authDomain: "why-kids-educations-v2.firebaseapp.com",
  projectId: "why-kids-educations-v2",
  storageBucket: "why-kids-educations-v2.appspot.com",
  messagingSenderId: "737629705745",
  appId: "1:737629705745:web:79353f2c9766fdc85ec3b0",
  measurementId: "G-5GH1W6PF6D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
