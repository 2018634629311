import React from "react";

interface ModalProps {
  customerName: string;
  customerPhoneNumber: string;
  handleCancel: () => void;
  handlePayment: () => void;
}

const ConfirmPaymentModal = ({
  handleCancel,
  handlePayment,
  customerName,
  customerPhoneNumber,
}: ModalProps) => {
  const isNewAccountMessage = customerName.includes(
    "Hệ thống sẽ tự động tạo tài khoản"
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative w-auto xl:max-dhmin::w-[40%] lg:max-xl:w-[60%]">
        <div className="flex-col gap-6 rounded-[40px] w-[100%] flex justify-center bg-white">
          <div className="bg-white text-[#142549] rounded-[30px] flex flex-col justify-center items-center px-8 py-6">
            <p className=" font-bold text-[35px] pv:max-md:text-[25px] mb-4 text-center">
              Xác Nhận Thanh Toán
            </p>
            <div className="space-y-4">
              {!isNewAccountMessage && (
                <>
                  <div className="flex gap-1">
                    <p className="text-[20px] font-bold pv:max-md:text-[15px]">
                      Tên khách hàng:
                    </p>
                    <p className="text-[20px] font-medium pv:max-md:text-[15px]">
                      {customerName}
                    </p>
                  </div>
                  <div className="flex gap-1">
                    <p className="text-[20px] font-bold pv:max-md:text-[15px]">
                      Email/SĐT:
                    </p>
                    <p className="text-[20px] font-medium pv:max-md:text-[15px]">
                      {customerPhoneNumber}
                    </p>
                  </div>
                </>
              )}
              {isNewAccountMessage && (
                <div className="text-[20px] font-medium pv:max-md:text-[15px] whitespace-pre-line text-center ">
                  {customerName}
                </div>
                // <p style={{ whiteSpace: "pre-line" }}>
                //   {customerName}
                // </p>
              )}

              <div className="flex justify-between gap-4">
                {/* button Cancel */}
                <div className="flex justify-end">
                  <button
                    className="bg-red-500 font-bold py-1 px-5 rounded-[33px] text-white"
                    onClick={handleCancel}
                  >
                    <p className="text-[20px] pv:max-md:text-[14px]">Huỷ</p>
                  </button>
                </div>
                {/* button thanh toán */}
                <div className="flex justify-end">
                  <button
                    className="bg-[#142548] font-bold py-1 px-5 rounded-[33px] text-white"
                    onClick={handlePayment}
                  >
                    <p className="text-[20px] pv:max-md:text-[14px]">
                      Thanh toán
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Close button */}
        <button
          onClick={handleCancel}
          className="absolute -top-5 -right-[50px] hover:text-gray-400"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ConfirmPaymentModal;
