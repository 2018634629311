import React from "react";
import backgroundImg from "../../assets/term/term_bg.png";
import Header from "../../components/Common/header/header";
import Footer from "../../components/Common/footer";

const PolicyRefund = () => {
  return (
    <div className="">
      <div
        className="w-[100%] h-full"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute z-50 w-full">
          <Header />
        </div>

        <div className="w-[100%] h-full justify-center flex pt-48 pv:max-ph:pt-32 ph:max-md:pt-40 pb-16">
          <div className="h-[75vh] w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] rounded-[33px] justify-center items-center scroll-smooth bg-[#FFFFFF] bg-opacity-40 px-[16px] py-[36px] ">
            <div className="items-center justify-center flex">
              <h1 className="font-[800] text-center pv:max-md:text-[25px] px-[50px] capitalize text-[48px] text-[#142548]">
                Chính sách đổi - trả và hoàn tiền
              </h1>
            </div>
            <div
              className=" h-[90%] overflow-y-auto [&::-webkit-scrollbar]:mr-[100px] [&::-webkit-scrollbar]:w-2
          [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-[#9DCBFC]
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-[#2A3E5E]"
            >
              <p className="text-[48px] px-[50px]"></p>
              <div
                className={`font-svn-gilroy w-full text-xl pv:max-md:text-[14px] font-medium leading-[normal] tracking-[0px] text-[#142548]  px-[16px] sm:px-[50px] `}
              >
                <span>
                  <div className="h-6" />
                  <ul className="list-disc pl-10">
                    <li>
                      Khách hàng đồng ý thanh toán khoản phí liên quan đến dịch vụ để nhận quyền sử dụng. Phí không bao gồm thuế và phí khác, và khách hàng chịu trách nhiệm cho các khoản này
                    </li>
                    <li>
                      Nếu không thanh toán đúng hạn, công ty có thể đình chỉ hoặc hủy dịch vụ, dẫn đến mất quyền truy cập.
                    </li>
                    <li>
                      Khách hàng chọn phương thức thanh toán trước khi hoàn tất giao dịch và có thể thay đổi thông tin tài khoản thanh toán bất cứ lúc nào.
                    </li>
                    <li>
                      Cung cấp phương thức thanh toán đồng nghĩa với việc khách hàng đảm bảo thông tin là chính xác và cho phép công ty tính phí cho dịch vụ.
                    </li>
                    <li>Hóa đơn có thể được gửi ngay khi đăng ký hoặc sau khi hoàn tất.
                    </li>
                    <li>Công ty có quyền thay đổi giá dịch vụ mà không cần thông báo, chỉ cần đăng tải trên phần mềm</li>
                    <li>Sau khi đăng ký, khách hàng sẽ nhận phiếu xác nhận dịch vụ. Nếu tài khoản bị chấm dứt, tất cả phiếu xác nhận sẽ mất giá trị.</li>
                    <li>Giao dịch hoàn tất là cuối cùng và không hoàn lại, nhưng khách hàng có thể hủy dịch vụ với quy định hoàn tiền cụ thể</li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PolicyRefund;
