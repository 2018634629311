import axios from "axios";
import { API_URL_BLOG } from "../config";

export const getAllBlog = async () => {
  try {
    const response = await axios.get(`${API_URL_BLOG}/news/`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getBlogDetail = async (key: string) => {
  try {
    const response = await axios.get(`${API_URL_BLOG}/news/${key}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getBlogDetailByKey = async (key: string) => {
  try {
    const response = await axios.get(`${API_URL_BLOG}/news/detail?path=${key}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
