interface HomeCardProps {
  urlLink: string;
  title: string;
  content: string;
  reverseRow: boolean;
}

function HomeCard({
  urlLink,
  title,
  content,
  reverseRow = false,
}: HomeCardProps) {
  const isImage = (link: string) => {
    return link.match(/\.(jpeg|jpg|gif|png|svg)$/) !== null;
  };

  const isVideo = (link: string) => {
    return link.match(/\.(mp4|webm|ogg)$/) !== null;
  };

  return (
    <div
      className={`w-full px-32 py-16 pv:max-md:px-5 pv:max-md:py-10 flex ${
        reverseRow ? "flex-row" : "flex-row-reverse"
      } justify-between gap-10 items-end pv:max-md:flex-col-reverse pv:max-md:justify-center pv:max-md:items-center`}
    >
      <div
        className={`w-[60%] h-auto md:max-xl:h-auto dvmax:max-dh:h-auto flex ${
          reverseRow ? "justify-start" : "justify-end"
        } object-contain pv:max-md:w-full items-end`}
      >
        {isImage(urlLink) && (
          <img
            src={urlLink}
            className="w-full object-contain h-auto md:max-xl:h-auto dvmax:max-dh:h-auto"
            alt={title}
          />
        )}
        {isVideo(urlLink) && (
          <video
            id="myvideo"
            className="rounded-[30px] w-full  object-fill flex h-4/5 md:max-xl:h-auto"
            controls
            controlsList="nodownload"
            poster="logo/poster_video.jpg"
          >
            <source src={urlLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="flex flex-col justify-end w-[40%] pv:max-md:w-full pv:max-md:text-center">
        <p className="uppercase text-center leading-tight tracking-wide font-[800] text-[#142548] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-xl:text-[35px] xl:max-dvmax:text-[50px] dvmax:max-dh:text-[70px]">
          {title.split(" ").slice(0, 2).join(" ")}
          <br />
          {title.split(" ").slice(2).join(" ")}
        </p>

        <p className="font-[500] text-wrap text-[#142548] text-[20px] dvmax:max-dh:text-[25px] lg:max-xl:text-[16px] xl:max-dvmax:text-[20px] sm:max-md:text-xl md:max-lg:text-[14px]">
          {content}
        </p>
      </div>
    </div>
  );
}

export default HomeCard;
