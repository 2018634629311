const FlipCard = ({
  imageURL,
  title,
  color,
  content,
}: {
  imageURL?: string;
  title?: string;
  color?: string;
  content?: string;
}) => {
  return (
    <div className="group h-[400px] w-[400px] pv:max-sm:h-[128px] pv:max-sm:w-[343px] sm:max-lg:w-[90%] sm:max-lg:h-[170px] lg:max-xl:w-[290px] lg:max-xl:h-[290px] xl:max-xlmax:h-[340px] xl:max-xlmax:w-[340px] xlmax:max-2xl:w-[410px] xlmax:max-2xl:h-[410px] 2xl:max-dvmax:w-[450px] 2xl:max-dvmax:h-[450px] dk:max-dhmin:w-[480px] dk:max-dhmin:h-[480px] [perspective:1000px]">
      <div className="relative h-full w-full rounded-[25px] transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
        {/* Front Face */}
        <div
          className="absolute inset-0 h-full w-full pv:max-lg:flex-row pv:max-lg:gap-5 flex flex-col items-center justify-center rounded-[25px] [backface-visibility:hidden]"
          style={{
            backgroundColor: color,
          }}
        >
          <img
            className="object-contain h-1/2 pv:max-sm:h-[77px] sm:max-lg:h-[100px] lg:max-xlmax:h-[140px] cursor-pointer"
            src={imageURL}
            alt=""
          />
          <div className="w-[315px] pv:max-md:w-[140px] lg:max-xlmax:w-[200px] xlmax:max-2xl:w-[270px] ">
            <p
              className={
                "pv:max-lg:text-[22px] text-[50px] pv:max-lg:leading-[40px] lg:max-xlmax:text-[30px] lg:max-xlmax:leading-[40.15px] xlmax:max-2xl:text-[40px] leading-[60.15px] font-[700] text-center text-white mt-4"
              }
            >
              {title}
            </p>
          </div>
        </div>

        {/* Back Face */}
        <div
          style={{
            backgroundColor: color,
          }}
          className="absolute inset-0 h-full w-full flex items-center rounded-[25px] px-10 text-center text-white [transform:rotateY(180deg)] [backface-visibility:hidden]"
        >
          <p className="text-[22px] pv:max-lg:text-[14px] lg:max-xlmax:text-[17px] xlmax:max-2xl:text-[22px] font-semibold text-justify">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
