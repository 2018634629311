// import packageAttributes from

interface bannerData {
  priority?: any;
  dateStart?: string;
  dateEnd?: any;
}

const Banner = ({ priority, dateStart, dateEnd }: bannerData) => {
  type PackagePriority = 1 | 2 | 3 | 4 | "default";

  const packageData: Record<
    PackagePriority,
    { backgroundColor: string; img: string; title: string }
  > = {
    1: { backgroundColor: "#65AFFF", img: "baby1", title: "3" },
    2: { backgroundColor: "#9FD356", img: "baby2", title: "6" },
    3: { backgroundColor: "#F7BF57", img: "baby3", title: "12" },
    4: { backgroundColor: "#F56177", img: "baby4", title: "Trọn đời" },
    default: { backgroundColor: "#0067B2", img: "", title: "" },
  };

  const getBackgroundColor = (priority?: number): string =>
    packageData[(priority as PackagePriority) || "default"].backgroundColor;

  const getImgPackage = (priority?: number): string =>
    packageData[(priority as PackagePriority) || "default"].img;

  const getTitlePackage = (priority?: number): string =>
    packageData[(priority as PackagePriority) || "default"].title;

  return (
    <div
      className="relative mb-8 w-full h-[280px] lg:max-xl:h-[300px] pv:max-md:h-[200px] rounded-3xl lg:h-[330px] shadow-inner"
      style={{ backgroundColor: getBackgroundColor(priority) }}
    >
      <div className="absolute top-12 left-6 lg:left-20 lg:top-11 pv:max-md:top-5">
        {priority == 0 || priority == null ? (
          <p className="uppercase text-white text-xl pv:max-md:text-[15.17px] font-bold lg:text-[33.37px]">
            Nâng cấp tài khoản để khám phá nội dung
          </p>
        ) : (
          <div>
            <p className="uppercase text-white text-xl pv:max-md:text-[15.17px] font-bold lg:text-[33.37px]">
              Bé đang dùng {getTitlePackage(priority)} gói
            </p>
            {priority == 4 ? (
              <div></div>
            ) : (
              <p className="text-white mt-2 text-sm font-bold lg:text-[22px]">
                (từ ngày {dateStart} - ngày {dateEnd})
              </p>
            )}
          </div>
        )}
      </div>
      <div className="absolute -bottom-1 w-full  pv:max-md:h-auto">
        <img
          className="w-full object-cover rounded-b-3xl"
          src="/logo/cloudobject.png"
          alt=""
        />
      </div>
      {priority == 0 || priority == null ? (
        <div className="uppercase absolute bottom-4 flex justify-around w-full pv:max-md:hidden">
          <div className="bg-[#8dd5eb] text-white pv:max-md:px-5 pv:max-md:py-2 px-16 py-3 pv:max-md:rounded-[20px] pv:max-md:text-[11px] rounded-[33px] flex-col items-center justify-center text-base font-bold cursor-pointer">
            <p className="text-center">Gói</p>
            <p className="text-center">3 tháng</p>
          </div>
          <div className="bg-[#bdd631] text-white px-16 py-3 rounded-[33px] flex-col items-center justify-center text-base font-bold cursor-pointer">
            <p className="text-center">Gói</p>
            <p className="text-center">6 tháng</p>
          </div>
          <div className="bg-[#2980ab] text-white px-16 py-3 rounded-[33px] flex-col items-center justify-center text-base font-bold cursor-pointer">
            <p className="text-center">Gói</p>
            <p className="text-center">12 tháng</p>
          </div>
          <div className="bg-[#f76f72] text-white px-16 py-3 rounded-[33px] flex-col items-center justify-center text-base font-bold cursor-pointer">
            <p className="text-center">Gói</p>
            <p className="text-center">trọn đời</p>
          </div>
        </div>
      ) : (
        <div>
          <div className="absolute md:max-lg:bottom-0 bottom-0 right-10">
            <img
              alt=""
              src={`/character_service_package/${getImgPackage(priority)}.png`}
              className="object-contain h-[250px] md:max-lg:h-[220px] pv:max-md:h-[130px]"
            />
          </div>
          {priority == 4 ? (
            <div></div>
          ) : (
            <a className="absolute bg-[#142548] text-white left-5 bottom-14 lg:bottom-10 lg:left-20 px-10 py-2 pv:max-md:bottom-5 rounded-[33px]">
              <p className=" text-base font-bold">{"Nâng cấp"}</p>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default Banner;
