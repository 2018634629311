import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Spin } from "antd";
import LearningCard from "../../components/Common/libraryStudy/learningDetailCard";
import ModalLesson from "../../components/Common/libraryStudy/modalLesson";
import { LoadingOutlined } from "@ant-design/icons";
import { getLessonByIslandID } from "../../services/lesson.service";
import { getAllIsland } from "../../services/island.service";
import { useGameContext } from "../../contexts/gameContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";
import InfoCard from "../../components/Common/libraryStudy/infoCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

interface Video {
  videoUrl: string;
  thumbnailUrl: string;
}

interface LessonInfo {
  isLandName: any;
  ID: string;
  lessonTitle?: string;
  schoolSectionTitle?: string;
  target?: string;
  subjectId?: string;
  description?: string;
  age?: string;
  videos?: Video[];
  games?: string[];
  data?: any;
  colors?: string[];
}

type IslandInfo = {
  ID: string;
  colors: string[];
  image: string;
  title: string;
  key: string;
  ItemMenu: ItemMenu[];
};

type ItemMenu = {
  key: number;
  label: string;
};

function LearningLibrary() {
  const navigate = useNavigate();
  const [infoList, setInfoList] = useState<IslandInfo[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [lessonData, setLessonData] = useState<LessonInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [active, setActive] = useState<number>(0);
  const [modalContent, setModalContent] = useState({
    lessonTitle: "",
    videoURl: "",
    thumbnailUrl: "",
    age: "",
    targetVideo: "",
    thumnailGameList: [],
  });
  const { fetchGames } = useGameContext();

  // Input change handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // Load lesson data
  const loadIslandLesson = async () => {
    try {
      setLoading(true);
      const lessonsPromises = [
        getLessonByIslandID("clt2orykx00036mjf4a434oms").then((result) => ({
          ...result,
          isLandName: "Kỹ năng xã hội",
        })),
        getLessonByIslandID("clt2opn1v00016mjf5vyw4t35").then((result) => ({
          ...result,
          isLandName: "Toán học",
        })),
        getLessonByIslandID("clt2oob6t00006mjfv54wljdl").then((result) => ({
          ...result,
          isLandName: "Tiếng Việt",
        })),
        getLessonByIslandID("clt2oqko600026mjfqz0p5mhv").then((result) => ({
          ...result,
          isLandName: "Văn hóa dân gian",
        })),
      ];

      const results = await Promise.all(lessonsPromises);

      const allLesson = results.flatMap((result: LessonInfo) => {
        const island = infoList.find(
          (info) => info.title.toLowerCase() === result.isLandName.toLowerCase()
        );
        return result.data.map((lesson: any) => ({
          ...lesson,
          isLandName: result.isLandName,
          colors: island ? island.colors : [],
        }));
      });

      console.log(allLesson, "allLesson");

      setLessonData(allLesson);
    } catch (error) {
      console.error("Error loading lessons:", error);
    } finally {
      setLoading(false);
    }
  };
  // open modal
  const handleCardClick = (lesson: any) => {
    const videoUrl = lesson.videos?.[0]?.videoUrl || "";
    const thumbnailUrl = lesson.videos?.[0]?.thumbnailUrl || "";
    setModalContent({
      lessonTitle: lesson.lessonTitle || "",
      videoURl: videoUrl,
      thumbnailUrl: thumbnailUrl,
      age: lesson.age || "",
      targetVideo: lesson.target || "",
      thumnailGameList: lesson.games || [],
    });
    // ***start integrate firebase analytics***
    logEvent(analytics, `web_lesson_view`, {
      page_title: lesson.lessonTitle,
    });
    setIsModalVisible(true);
  };
  // close modal
  const handleCancelModal = () => {
    setIsModalVisible(false);
  };

  const handleLogOut = () => {
    // record logout time
    const logoutTime = Date.now();
    const loginTime = Number(localStorage.getItem("loginTimeSchool"));
    const sessionDuration = logoutTime - loginTime;

    // remove 'loginTimeSchool' on localStorage
    localStorage.removeItem("loginTimeSchool");

    // convert Date to hh:mm:ss am/pm
    const hours = Math.floor(sessionDuration / (1000 * 60 * 60));
    const minutes = Math.floor(
      (sessionDuration % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((sessionDuration % (1000 * 60)) / 1000);
    const readableSessionDuration = `${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;

    // show logout time
    // console.log(`Logout Time: ${readableLogoutTime}`);
    // console.log(`Session Duration: ${readableSessionDuration}`);
    // ***start integrate firebase analytics***
    logEvent(analytics, "web_session_login_school", {
      duration: readableSessionDuration,
    });
    // remove session
    sessionStorage.removeItem("accessTokenSchool");
    sessionStorage.removeItem("refreshTokenSchool");

    // Điều hướng về trang giới thiệu
    navigate("/library-introduction");
  };

  // Initialize the data
  const init = async () => {
    await fetchGames();
    const dataIsland = await getAllIsland();
    setInfoList(dataIsland.data);
  };

  useEffect(() => {
    if (infoList?.length > 0) {
      loadIslandLesson();
    }
  }, [infoList]);

  useEffect(() => {
    init();
  }, []);

  // Filter lessons based on the input value
  const filteredLessons = lessonData.filter(
    (lesson) =>
      lesson.lessonTitle?.toLowerCase().includes(inputValue.toLowerCase()) ||
      lesson.target?.toLowerCase().includes(inputValue.toLowerCase()) ||
      lesson.schoolSectionTitle
        ?.toLowerCase()
        .includes(inputValue.toLowerCase()) ||
      lesson.isLandName.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div className="bg-[#F9FEFF]">
      {/* <Header searchTextChangeColor={true} /> */}
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pt-48 pv:max-ph:pt-32 ph:max-md:pt-40">
        <img
          className="w-[80%]"
          src="library_img/header/school_mark_2.png"
          alt=""
        />
      </div>
      <div
        className="relative"
        style={{
          backgroundImage: `url("/background/bg_for_school_repeat.png")`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "contain",
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex justify-between w-[90%]">
            <div
              className="bg-[#D1E7FF] flex items-center rounded-full px-10 text-[24px] pv:max-md:px-[10px] pv:max-md:text-[10px] font-bold text-[#65AFFF]"
              onClick={handleLogOut}
            >
              Đăng xuất
            </div>
            <Input
              variant={"borderless"}
              className="w-[400px] h-[70px] pv:max-md:w-[163px] pv:max-md:h-[22px] rounded-full bg-[#D1E7FF] px-10 pv:max-md:px-[10px] text-[#65AFFF] uppercase text-[24px] pv:max-md:text-[10px] font-bold flex items-center"
              placeholder="TÌM KIẾM HỌC LIỆU"
              prefix={
                <img
                  src="/logo/search_blue.png"
                  alt="Search Icon"
                  className="w-7 h-7 pv:max-md:w-[7px] pv:max-md:h-[7px] object-contain mr-4 "
                />
              }
              onChange={handleInputChange}
            />
          </div>

          <div className="w-[90%] pv:max-md:w-full py-1 mt-10">
            <div className="flex justify-between gap-[30px] pv:max-xl:flex-wrap pv:max-xl:justify-center ">
              {loading ? (
                <div className="flex justify-center w-full items-center my-10">
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 130 }} spin />
                    }
                  />
                </div>
              ) : inputValue === "" ? (
                <>
                  <div className="lg:max-dh:hidden w-full">
                    <Swiper
                      modules={[EffectCoverflow, Pagination]}
                      spaceBetween={0}
                      slidesPerView="auto"
                      centeredSlides={true}
                      onSlideChange={(cur: SwiperCore) =>
                        setActive(cur.realIndex)
                      }
                      coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 300,
                        modifier: 1,
                        slideShadows: true,
                      }}
                      className="flex justify-center items-center place-content-center h-[600px] pv:max-md:h-[400px] "
                    >
                      {infoList.map((info, index) => (
                        <SwiperSlide
                          key={index}
                          className="transition-transform duration-300 flex justify-center items-center content-center"
                          style={{
                            width: "fit-content",
                            maxWidth: "100%",
                            // height:"100%"
                          }}
                        >
                          <div
                            className={`transition-all duration-300 ${
                              active === index ? "scale-100" : "scale-75"
                            }`}
                          >
                            <InfoCard info={info} />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="pv:max-lg:hidden w-[100%] flex justify-between items-center">
                    {infoList.map((info, index) => (
                      <InfoCard key={index} info={info} />
                    ))}
                  </div>
                </>
              ) : (
                <div className="w-[100%] flex justify-items-stretch flex-wrap gap-5">
                  {filteredLessons.length > 0 ? (
                    filteredLessons.map((lesson, index) => (
                      <LearningCard
                        key={index}
                        colorCardLv1={lesson.colors![0]}
                        colorCardLv2={lesson.colors![1]}
                        colorCardLv3={lesson.colors![2]}
                        handleClick={() => handleCardClick(lesson)}
                        lessonTitle={lesson.lessonTitle}
                        age={lesson.age}
                        videoURl={lesson.videos![0]?.videoUrl || ""}
                        thumnail={
                          lesson.videos![0]?.thumbnailUrl ||
                          "/library_img/icon/no-thumbnail.jpg"
                        }
                        thumnailGame={lesson.games}
                        titleVideo={lesson.target}
                      />
                    ))
                  ) : (
                    <div className="w-full flex justify-center items-center">
                      <p className="text-red-500 text-xl font-bold text-center p-4 border border-red-500 rounded-md">
                        Không tìm thấy bài học nào phù hợp với từ khóa của bạn.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <img
          className="w-full mt-10 pv:max-md:hidden"
          src="library_img/footer/footer_library.png"
          alt=""
        />
      </div>
      {/* Modal Component */}
      <ModalLesson
        isModalVisible={isModalVisible}
        handleCancel={handleCancelModal}
        videoURl={modalContent.videoURl}
        listThumnaiGamelURl={modalContent.thumnailGameList}
        data={modalContent}
      />
      <Footer />
    </div>
  );
}

export default LearningLibrary;
