import axiosConfigSchool from "./axiosConfigSchool";

export const getAllPackageService = async () => {
  try {
    const response = await axiosConfigSchool({
      method: "GET",
      url: "/package",
    });
    return response.data;
  } catch (error) {
    return "Fail";
  }
};

export const getPackageServiceDetail = async (id: string) => {
  try {
    console.log("Package ID:", id);
    const encodedId = encodeURIComponent(id);
    const response = await axiosConfigSchool({
      method: "GET",
      url: `/package/${encodedId}`,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching package details:", error);
    return "Fail";
  }
};
