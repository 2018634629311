import React from "react";
interface ModalProps {
  handleCancel: () => void;
}

const ModalConfirm = ({ handleCancel }: ModalProps) => {
  return (
    <div className="fixed inset-0 w-full bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div
        className="w-fit pv:max-md:w-[80%] text-[#142548] py-16 px-40 pv:max-md:px-[40px] rounded-[30px] relative"
        style={{
          backgroundImage: "url('/background/bg_blog_modal.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-full text-[#142548] rounded-[30px] flex items-center justify-center">
          <div className="flex flex-col items-center justify-center place-content-center">
            <p className="font-[800] text-[50px] text-center uppercase pv:max-md:text-[18px]">
              Đăng kí nhận tư vấn thành công
            </p>
            <p className="text-[25px] font-medium text-center pv:max-md:text-[15px]">
              Why Kids sẽ liên hệ ba mẹ để tư vấn trong thời gian sớm nhất
            </p>
            <img
              src="/logo/banner_header.png"
              alt=""
              className="my-10 object-contain pv:max-md:h-[137px]"
            />
            <button
              type="button"
              className="bg-[#142548] font-bold text-white px-10 py-2 rounded-[23px] mt-4"
              onClick={handleCancel} // Close modal when clicked
            >
              Hoàn thành
            </button>
          </div>
        </div>
        <button className="absolute top-0 right-0 p-5" onClick={handleCancel}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-[#142548] hover:text-white font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ModalConfirm;
