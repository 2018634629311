import React from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../../components/Common/footer';
import Header from '../../components/Common/header/header';

// const PaymentProcessAccessContext = React.createContext(false);



const PaymentProcess: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const paymentAccess = sessionStorage.getItem("paymentProcess");


  return ( 
    (paymentAccess!='true') ?
    <Navigate to="/" state={{ from: location }} replace />
:   
    <div className='bg-gray-100'>
        <Header/>
        <div className='min-h-[50vh] flex p-9 items-center justify-center'>
            <p className='text-[30px] italic text-[#142548]'>Đang trong quá trình thanh toán...</p>
        </div>
    <Footer/>
    </div>
    
    
  );
};

export default PaymentProcess;
