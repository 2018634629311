const IslandCard = ({
  imageURL,
  title,
  color,
  colorBody,
  lessonsCount,
  starsCount,
  timeCount,
}: {
  imageURL?: string;
  title?: string;
  color?: string;
  content?: string;
  colorBody?: string;
  lessonsCount?: number;
  starsCount?: number;
  timeCount?: number;
}) => {
  return (
    <div className="group h-[292px] w-[337px] pv:max-md:w-[163px] pv:max-md:h-[141px] [perspective:1000px]">
      <div className="relative h-full w-full rounded-[9.25px] transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
        {/* Front Face */}
        <div
          className="absolute inset-0 h-full w-full flex flex-col items-center justify-end gap-1 rounded-[9.25px] [backface-visibility:hidden]"
          style={{
            backgroundColor: colorBody,
          }}
        >
          <img
            className="object-contain h-[200px] pv:max-md:h-[90px] cursor-pointer"
            src={imageURL}
            alt=""
          />
          <div
            className="w-full flex items-center justify-center py-3 rounded-[10px]"
            style={{ backgroundColor: color }}
          >
            <p className="text-[25px] pv:max-md:text-[12px] font-[700] text-center text-white">
              {title}
            </p>
          </div>
        </div>

        {/* Back Face */}
        <div
          style={{
            backgroundColor: colorBody,
          }}
          className="absolute inset-0 h-full w-full flex flex-col justify-between rounded-[9.25px]  text-center text-white [transform:rotateY(180deg)] [backface-visibility:hidden]"
        >
          <div className="flex flex-row justify-around w-full h-full px-2 py-2">
            {/* Lessons */}
            <div className="text-center text-[#142548] bg-white w-[30%] rounded-[5px] flex flex-col items-center justify-start p-4">
              <img
                src="/account/tv.png"
                alt="tv"
                className="h-[100px] mx-auto mb-2 object-contain"
              />
              <div className="relative text-[22px] font-bold flex flex-col items-center">
                <p className=" absolute -left-7 -top-6">{starsCount}</p>
                <div className="w-[60px] h-[1px] bg-[#142548] rotate-[-25deg] my-1 absolute z-50"></div>
                <p className="absolute  -left-2 top-2">87</p>
              </div>
              <p className="text-[14px] font-bold text-[#65AFFF] mt-14">
                (bài học)
              </p>
            </div>
            {/* Stars */}
            <div className="text-center text-[#142548] bg-white w-[30%] rounded-[5px] flex flex-col items-center justify-start p-4">
              <img
                src="/account/star.png"
                alt="Stars"
                className="h-[100px] mx-auto mb-2 object-contain"
              />
              <div className="relative text-[22px] font-bold flex flex-col items-center">
                <p className=" absolute -left-7 -top-6">{starsCount}</p>
                <div className="w-[60px] h-[1px] bg-[#142548] rotate-[-25deg] my-1 absolute z-50"></div>
                <p className="absolute  -left-2 top-2">1482</p>
              </div>
              <p className="text-[14px] font-bold text-[#F89628] mt-14">
                (sao)
              </p>
            </div>
            {/* Time */}
            <div className="text-center text-[#142548] bg-white w-[30%] rounded-[5px] flex flex-col items-center justify-start p-4">
              <img
                src="/account/lock.png"
                alt="lock"
                className="h-[100px] mx-auto mb-2 object-contain"
              />
              <div className="relative text-[22px] font-bold flex flex-col items-center">
                <p className=" absolute -left-7 -top-6">{timeCount}</p>
                <div className="w-[60px] h-[1px] bg-[#142548] rotate-[-25deg] my-1 absolute z-50"></div>
                <p className="absolute  -left-2 top-2">45</p>
              </div>
              <p className="text-[14px] font-bold text-[#F56177] mt-14">
                (phút)
              </p>
            </div>
          </div>
          <div
            className="w-full flex items-center justify-center py-3 rounded-[10px]"
            style={{ backgroundColor: color }}
          >
            <p className="text-[25px] font-[700] text-center text-white">
              {title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IslandCard;
