import React, { useState, useEffect, useRef } from "react";
import { Carousel, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Define the infoList array here
const infoList = [
  {
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/daotoan.gif",
    title: "toán",
    content: "Phát triển tư duy logic và kỹ năng tính toán cho trẻ",
  },
  {
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/tieng_viet.gif",
    title: "tiếng việt",
    content: "Giúp trẻ củng cố và phát triển kỹ năng đọc và viết Tiếng Việt",
  },
  {
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/van_hoa.gif",
    title: "văn hóa dân gian",
    content:
      "Truyền tải và giữ gìn văn hóa truyền thống Việt Nam, giúp trẻ hiểu và yêu thêm nền văn hóa dân tộc",
  },
  {
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/xa_hoi.gif",
    title: "Kỹ Năng Xã Hội",
    content:
      "Trang bị cho trẻ những kỹ năng sống cần thiết, giúp trẻ tự tin và hòa nhập tốt trong môi trường xã hội",
  },
  {
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/khoa_hoc.gif",
    title: "Khoa Học",
    content:
      "Kích thích sự tò mò và khám phá khoa học, giúp trẻ yêu thích và hiểu biết về thế giới xung quanh",
  },
  {
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/nghe_thuat.gif",
    title: "Nghệ Thuật",
    content:
      "Phát triển khả năng sáng tạo và tư duy nghệ thuật, giúp trẻ thể hiện và khám phá tài năng của mình",
  },
];

// Define the ArrowButton props type
interface ArrowButtonProps {
  direction: "prev" | "next";
  onClick: () => void;
}
// ArrowButton component
const ArrowButton: React.FC<ArrowButtonProps> = ({ direction, onClick }) => (
  <img
    className={`w-[50px] absolute z-50 pv:max-md:top-[130px] md:top-[230px] top-[180px] lg:top-[250px] xl:top-[300px] h-[70px] dvmax:max-dh:h-[150px] dvmax:max-dh:w-[100px] cursor-pointer ${
      direction === "prev"
        ? "transform left-5 rotate-180 mr-[-40px]"
        : "right-5 ml-[-40px]"
    }`}
    src="logo/arrow.png"
    alt={direction === "prev" ? "Previous" : "Next"}
    onClick={onClick}
  />
);

const IslandInfo: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(
    Array(infoList.length).fill(false)
  );
  const [timeoutExceeded, setTimeoutExceeded] = useState(false);
  const carouselRef = useRef<any>(null);
  const currentInfo = infoList[currentIndex];

  useEffect(() => {
    if (!loadedImages[currentIndex]) {
      const timer = setTimeout(() => {
        if (loading) {
          setTimeoutExceeded(true);
          setLoading(false);
        }
      }, 2000);
      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, [currentIndex, loading, loadedImages]);

  const handleImageLoad = () => {
    setLoading(false);
    setLoadedImages((prev) => {
      const newLoadedImages = [...prev];
      newLoadedImages[currentIndex] = true;
      return newLoadedImages;
    });
  };

  const handleImageError = () => {
    setTimeoutExceeded(true);
    setLoading(false);
  };

  const handlePrev = () => {
    setLoading(true);
    setTimeoutExceeded(false);
    carouselRef.current.prev();
  };

  const handleNext = () => {
    setLoading(true);
    setTimeoutExceeded(false);
    carouselRef.current.next();
  };
  return (
    <div>
      {/* hidden screen 768 -> 5000 */}
      <div className="md:max-dh:hidden flex flex-col py-6">
        <div className="flex justify-center">
          <div className="w-[100%] px-4">
            <p className="uppercase font-bold text-center text-[#142548] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-dh:text-[60px]">
              Toàn diện
            </p>
            <p className="font-normal text-wrap text-[#142548] text-[20px] sm:max-md:text-xl md:max-lg:text-[14px]">
              {/* Ứng dụng cung cấp các bài học và hoạt động sáng tạo về Toán, Tiếng
              Việt, Kỹ năng sống, Văn hóa - Dân gian, Khoa học và Nghệ thuật,
              giúp trẻ phát triển kỹ năng mềm và kiến thức học tập toàn diện. */}
              Mọi kỹ năng và kiến thức cho bé yêu gói gọn trong một ứng dụng duy
              nhất - phát triển toàn diện từ học tập đến kỹ năng sống, tất cả
              chỉ trong tầm tay!
            </p>
          </div>
        </div>

        <div className="flex relative justify-center items-center px-5">
          {loading && !timeoutExceeded ? (
            <div className=" w-[100vw] h-[400px] flex justify-center items-center">
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
              />
            </div>
          ) : (
            <div>
              <Carousel
                ref={carouselRef}
                dots={false}
                arrows
                nextArrow={
                  <ArrowButton direction="next" onClick={handleNext} />
                }
                prevArrow={
                  <ArrowButton direction="prev" onClick={handlePrev} />
                }
                className=" w-[100vw] h-[400px]"
                afterChange={(current) => setCurrentIndex(current)}
              >
                {infoList.map((info, index) => (
                  <div key={index} className="w-full">
                    <img
                      className="w-[100%] mx-auto h-[350px] object-contain block"
                      src={info.imgLink}
                      alt={info.title}
                      onLoad={handleImageLoad}
                      onError={handleImageError}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </div>

        <div className="flex justify-center px-6">
          <div className="w-[100%] p-4 h-full rounded-2xl bg-[#F9FEFF]">
            <p className="uppercase text-[#142548] font-bold text-center  sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px] lg:max-dh:text-[60px]">
              {currentInfo.title}
            </p>
            <p
              className="text-[#142548] text-center text-[20px] sm:max-md:text-xl md:max-lg:text-[14px]"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {currentInfo.content}
            </p>
          </div>
        </div>
      </div>
      {/* hidden screen 280 -> 768 */}
      <div className="pv:max-md:hidden w-full flex justify-between dvmax:max-dh:mt-6 items-center dvmax:max-dh:h-[900px] h-[650px] md:h-[500px] lg:h-[600px] px-20 py-16 pv:max-md:px-5">
        <div className="w-[60%] items-center place-content-center relative">
          <Carousel
            ref={carouselRef}
            dots={false}
            arrows
            nextArrow={<ArrowButton direction="next" onClick={handleNext} />}
            prevArrow={<ArrowButton direction="prev" onClick={handlePrev} />}
            className="w-full h-full"
            afterChange={(current) => setCurrentIndex(current)}
          >
            {infoList.map((info, index) => (
              <img
                className="w-full h-[650px] md:h-[500px] dvmax:max-dh:h-[900px] lg:h-[600px] object-contain block"
                src={info.imgLink}
                alt={info.title}
                onLoad={handleImageLoad}
                onError={handleImageError}
              />
            ))}
          </Carousel>
          <div className="absolute left-[45px] bottom-[30px]">
            <img
              className="w-[300px] h-[180px] dvmax:max-dh:w-[480px] dvmax:max-dh:h-[250px]  md:w-[240px] md:h-[140px] lg:w-[240px] lg:h-[160px] xl:w-[260px] xl:h-[180px]"
              src="logo/sign.png"
              alt="sign"
            />
          </div>
          <div className=" absolute left-[40px] bottom-[135px] dvmax:max-dh:left-[60px] dvmax:max-dh:bottom-[185px] lg:max-xl:bottom-[125px] md:max-lg:bottom-[100px] flex justify-center h-[50px] w-[240px] dvmax:max-dh:w-[415px] md:w-[240px] lg:w-[245px] items-center">
            <p className="font-bold uppercase text-[24px]  dvmax:max-dh:text-[45px] md:text-[20px] lg:text-[22px] xl:text-[24px] text-center stroke-text overflow-hidden text-ellipsis whitespace-nowrap">
              {currentInfo.title}
            </p>
          </div>
        </div>
        <div className="w-[35%] flex-col ">
          <div className="">
            <p className="font-[800] text-[#142548] leading-tight tracking-wider uppercase text-center text-[60px] lg:max-xl:text-[35px] xl:max-dvmax:text-[50px] dvmax:max-dh:text-[70px]">
              Toàn diện
            </p>
            <p className="font-normal text-[#142548] dvmax:max-dh:mt-10 text-[25px] dvmax:max-dh:text-[28px] md:text-[16px] lg:text-[20px] xl:text-[25px]">
              {/* Ứng dụng cung cấp các bài học và hoạt động sáng tạo về Toán, Tiếng
              Việt, Kỹ năng sống, Văn hóa - Dân gian, Khoa học và Nghệ thuật,
              giúp trẻ phát triển kỹ năng mềm và kiến thức học tập toàn diện. */}
              Mọi kỹ năng và kiến thức cho bé yêu gói gọn trong một ứng dụng duy
              nhất - phát triển toàn diện từ học tập đến kỹ năng sống, tất cả
              chỉ trong tầm tay!
            </p>
          </div>
          <div className="mt-6 flex justify-center h-fit items-center">
            <div className="w-full py-5  rounded-[40px] h-fit items-center bg-[#f2feff] ">
              <p className="text-[#142548] text-center uppercase dvmax:max-dh:text-[36px] font-[800] text-[30px] md:text-[20px] lg:text-[23px] xl:text-[30px]">
                đảo {currentInfo.title}
              </p>
              <p className="text-[#142548] px-10 text-center text-[25px]  md:text-[14px] dvmax:max-dh:text-[25px] lg:max-xl:text-[16px] xl:max-dvmax:text-[20px]">
                {currentInfo.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IslandInfo;
