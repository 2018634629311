import { notification, Result } from 'antd';
import React, {  useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PaymentTerm from '../../utils/payment_terms';
import Footer from '../../components/Common/footer';

// const PaymentProcessAccessContext = React.createContext(false);



const PaymentHandler: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const paymentAccess = sessionStorage.getItem("paymentProcess");
  let [searchParams, setSearchParams] = useSearchParams();

  const vnpayCallbackInfo = {
    vnp_Amount: searchParams.get("vnp_Amount"),
    vnp_BankCode: searchParams.get("vnp_BankCode"),
    vnp_BankTranNo: searchParams.get("vnp_BankTranNo"),
    vnp_CardType: searchParams.get("vnp_CardType"),
    vnp_OrderInfo: searchParams.get("vnp_OrderInfo"),
    vnp_PayDate: searchParams.get("vnp_PayDate"),
    vnp_ResponseCode: searchParams.get("vnp_ResponseCode"),
    vnp_TmnCode: searchParams.get("vnp_TmnCode"),
    vnp_TransactionNo: searchParams.get("vnp_TransactionNo"),
    vnp_TransactionStatus: searchParams.get("vnp_TransactionStatus"),
    vnp_TxnRef: searchParams.get("vnp_TxnRef"),
    vnp_SecureHash: searchParams.get("vnp_SecureHash")
  }

  type PaymentTermKey = keyof typeof PaymentTerm;

  const handleTitlePaymentError = (responseCode:string):PaymentTermKey => {
    let responseStr = ""
    switch (responseCode) {
      case "09":
        return "payments_status_AccNotInternetBanking"

      case "10":
        return "payments_status_AccAuthInforWrong3Times"

      case "11":
        return "payments_status_paymentExpired"

      case "12":
        return "payments_status_AccIsBlocked"

      case "13":
        return "payments_status_WrongOTP"

      case "24":
        return "payments_status_TransCancelled"

      case "51":
        return "payments_status_AccNotEnounghBalance"

      case "65":
        return "payments_status_MaxTransCapPerDay"

      case "79":
        return "payments_status_PaymentAuthWrong3Times"


      default:
        return "payments_status_UnknowErrContactAdmin"

    }
    return "payments_status_UnknowErrContactAdmin"
  }

  useEffect(() => {
    // Kiểm tra URL sau thanh toán, ví dụ có tham số 'status' hoặc 'transactionId'
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get('vnp_BankTranNo');
    
  }, [location, navigate]);

  return ( 
    (paymentAccess!='true') ?
    <Navigate to="/" state={{ from: location }} replace />
:
    <div>
      <div className="min-h-[80vh] flex items-center justify-center bg-gray-100 p-4 sm:p-8">
      <div className="bg-white p-6 sm:p-8 md:p-12 rounded-lg shadow-lg text-center w-full max-w-md mt-[50px]">
        <Result
          status={
            (vnpayCallbackInfo.vnp_ResponseCode == "00")
              ? "success"
              : (vnpayCallbackInfo.vnp_ResponseCode == "07")
                ? "warning"
                : (vnpayCallbackInfo.vnp_ResponseCode == "75")
                  ? "info"
                  : (vnpayCallbackInfo.vnp_ResponseCode == "09" || vnpayCallbackInfo.vnp_ResponseCode == "10" || vnpayCallbackInfo.vnp_ResponseCode == "11" ||
                    vnpayCallbackInfo.vnp_ResponseCode == "12" || vnpayCallbackInfo.vnp_ResponseCode == "13" || vnpayCallbackInfo.vnp_ResponseCode == "24" ||
                    vnpayCallbackInfo.vnp_ResponseCode == "51" || vnpayCallbackInfo.vnp_ResponseCode == "65" || vnpayCallbackInfo.vnp_ResponseCode == "79")
                    ? "error"
                    : "500"
          }
          title={
            (vnpayCallbackInfo.vnp_ResponseCode == "00")
              ? PaymentTerm["payments_status_success"]
              : (vnpayCallbackInfo.vnp_ResponseCode == "07")
                ? PaymentTerm["payments_status_warning"]
                : PaymentTerm["payments_status_incomplete"]
          }
          subTitle={
            (vnpayCallbackInfo.vnp_ResponseCode == "00")
              ? PaymentTerm["payments_status_success_desc"]
              : (vnpayCallbackInfo.vnp_ResponseCode == "07")
                ? PaymentTerm["payments_status_warning_desc"]
                : (vnpayCallbackInfo.vnp_ResponseCode == "75")
                  ? PaymentTerm["payments_status_bankOutOfOrder"]
                  : PaymentTerm[handleTitlePaymentError(vnpayCallbackInfo.vnp_ResponseCode??"99")]
          }
        />
        <div className="flex justify-between">          
          <button className=" w-[40%] mx-auto py-3 bg-secondary1 rounded-lg p-4">
            <p
              className="text-[#142548] text-[20px] font-roboto_bold"
              onClick={() => {
                notification.success({
                  message: PaymentTerm["payments_status_tksForReg"],
                  placement: "topRight",
                  duration: 2,
                });
                navigate("/")
              }}
            >
              {PaymentTerm["payments_status_DONE"]}
            </p>
          </button>
        </div>
      </div>
     
    </div>
    <Footer/>
    </div>
    
    
  );
};

export default PaymentHandler;
