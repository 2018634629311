import axiosConfigSchool from "./axiosConfigSchool";

export const getAllIsland = async () => {
  try {
    const response = await axiosConfigSchool({
      method: "GET",
      url: "/v2/study-material/island",
    });
    return response.data;
  } catch (error) {
    return "Fail";
  }
};
