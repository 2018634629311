import React from 'react';
import { Navigate } from 'react-router-dom';

// Giả sử có một hàm để kiểm tra người dùng đã đăng nhập hay chưa
const isAuthenticated = (): boolean => {
  // Kiểm tra trạng thái đăng nhập của người dùng
  // Có thể là kiểm tra token trong localStorage, session, v.v.
  return !!sessionStorage.getItem('accessTokenSchool');
};

interface PrivateRouteProps {
  component: React.FC;
}

// Tạo PrivateRoute để kiểm tra quyền truy cập
const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component }) => {
  return isAuthenticated() ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;