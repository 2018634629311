import { useState } from "react";

const ImagePolicy13 =
  process.env.PUBLIC_URL + "/policyGuide/policy_guide_13.png";
const ImagePolicy14 =
  process.env.PUBLIC_URL + "/policyGuide/policy_guide_14.png";

const PayWithBankCard = ({ t }: any) => {
  const [stage, setStage] = useState(0);
  const handleOnChangeStage = (stage: any) => {
    setStage(stage);
  };

  return (
    <div className="px-8 py-12 w-[90%] max-w-[100%] rounded-xl bg-white flex drop-shadow-2xl">
      <div className="w-[40%] mr-6 flex flex-col justify-center items-center">
        <img src={ImagePolicy13} alt="VN PAY-QR" className="scale-75" />
        <img src={ImagePolicy14} alt="VN PAY-QR" className="scale-75" />
      </div>
      <div className="grow-1 flex flex-col justify-evenly items-start">
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 0
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(0);
            }}
          >
            {1}
          </span>
          <div
            className={`w-[220px] lg:w-[350px]   flex flex-col justify-center ${
              stage == 0 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 0 ? "mb-4" : ""}`}>
              Xác nhận giỏ hàng
            </span>

            {stage == 0 && (
              <span className="whitespace-normal text-justify text-base font-bold text-black">
                {/* {t("pay_policy_confirm_desc_bankCard")} */}
                Bạn chọn sản phẩm dịch vụ và chọn Thanh toán ngay hoặc Đặt hàng
                Tại trang thanh toán, quý khách vui lòng kiểm tra sản phẩm đã
                đặt, điền thông tin người nhận hàng, chọn phương thức thanh toán
                VN PAY và nhấn nút Đặt hàng ngay.
              </span>
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 1
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(1);
            }}
          >
            {2}
          </span>
          <div
            className={`w-[220px] lg:w-[350px]   flex flex-col justify-center ${
              stage == 1 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 1 ? "mb-4" : ""}`}>
              Chọn phương thức thanh toán
            </span>

            {stage == 1 && (
              <span className="whitespace-normal text-justify text-base font-bold text-black">
                Màn hình thanh toán chuyển sang giao diện cổng thanh toán VN
                PAY. Chọn phương thức Thẻ nội địa và tài khoản ngân hàng rồi
                chọn ngân hàng muốn thanh toán bằng thẻ trong danh sách
              </span>
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 2
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(2);
            }}
          >
            {3}
          </span>
          <div
            className={`w-[220px] lg:w-[350px]   flex flex-col justify-center ${
              stage == 2 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 2 ? "mb-4" : ""}`}>Xác minh</span>

            {stage == 2 && (
              <span className="whitespace-normal text-justify text-base font-bold text-black">
                Vui lòng nhập thông tin thẻ/tài khoản theo yêu cầu và chọn Tiếp
                tục. Mã OTP sẽ được gửi về điện thoại đã đăng ký, nhập mã OTP để
                hoàn tất giao dịch
                <br />
                <br />
                Lưu ý: Giao dịch sẽ hết hạn sau 15 phút
              </span>
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 3
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(3);
            }}
          >
            {4}
          </span>
          <div
            className={`w-[220px] lg:w-[350px]   flex flex-col justify-center ${
              stage == 3 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 3 ? "mb-4" : ""}`}>
              {/* {t("pay_policy_reCheck")} */}
              Kiểm tra lại thông tin
            </span>

            {stage == 3 && (
              <span className="whitespace-normal text-justify text-base font-bold text-black">
                Khi thanh toán hoàn tất, bạn sẽ nhận được thông báo xác nhận đơn
                hàng thành công trên website
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayWithBankCard;
