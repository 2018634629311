import React from "react";
import { Blog } from "../../../pages/BlogAndNews";
import { useNavigate } from "react-router-dom";

const ManyPeopleReadCard = ({
  blog,
  isLast,
}: {
  blog: Blog;
  isLast: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="mt-5 cursor-pointer"
      onClick={() => {
        navigate(`../tin-tuc/${blog.path}`, {
          state: { dataDetail: blog },
        });
      }}
    >
      <div className="flex justify-between items-end">
        <img
          src={blog.titlepic || "default-image-url.jpg"}
          alt={blog.title || "Blog image"}
          className="bg-gray-400 pv:max-md:w-[60%] pv:max-md:h-[110px] w-[200px] h-[116px] object-cover rounded-xl"
        />
        <div className="w-[217px] pv:max-md:w-[35%]">
          <p className="text-[#122548] font-bold text-[18px] pv:max-md:text-[14px]">
            {blog.title || "Untitled"}
          </p>
        </div>
      </div>
      {/* Conditionally render the border */}
      {!isLast && <div className="w-full h-[1.11px] mt-5 bg-[#949494]" />}
    </div>
  );
};
export default ManyPeopleReadCard;
