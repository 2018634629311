import React from "react";
import IslandCard from "./IslandCard";

const dataIslandCardData = [
  {
    id: 1,
    name: "Đảo Tiếng Việt",
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/tieng_viet.gif",
    color: "#FFA500",
    colorBody: "#fadca0",
    stats: { lessons: 11, stars: 11, time: 12 },
  },
  {
    id: 2,
    name: "Đảo Toán Logic",
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/daotoan.gif",
    colorBody: "#b3d7ff",
    color: "#00BFFF",
    stats: { lessons: 12, stars: 44, time: 12 },
  },
  {
    id: 3,
    name: "Đảo Văn Hóa Dân Gian",
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/van_hoa.gif",
    color: "#32CD32",
    colorBody: "#cee8a9",
    stats: { lessons: 5, stars: 12, time: 6 },
  },
  {
    id: 4,
    name: "Đảo Kỹ Năng Xã Hội",
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/xa_hoi.gif",
    color: "#008080",
    colorBody: "#83c0d6",
    stats: { lessons: 35, stars: 22, time: 15 },
  },
  {
    id: 5,
    name: "Đảo Khoa Học",
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/khoa_hoc.gif",
    colorBody: "#faafba",
    color: "#FF69B4",
    stats: { lessons: 22, stars: 230, time: 32 },
  },
  {
    id: 6,
    name: "Đảo Nghệ Thuật",
    imgLink:
      "https://storage.googleapis.com/why_kid_static_content/WEBSITE/ISLAND_GIF/nghe_thuat.gif",
    colorBody: "#c6e9f5",
    color: "#87CEFA",
    stats: { lessons: 87, stars: 211, time: 35 },
  },
];

const SummaryCard = () => {
  return (
    <div className="py-12 bg-white rounded-3xl animate-appear">
      <p className="mb-10 px-20 uppercase text-3xl pv:max-md:text-[22px] font-bold text-center text-[#142548]">
        Bảng tổng hợp
      </p>
      <div className="grid grid-cols-3 gap-2 px-10 pv:max-md:grid-cols-2 pv:max-md:px-2">
        {dataIslandCardData.map((island, index) => {
          return (
            <IslandCard
              key={index}
              imageURL={island.imgLink}
              title={island.name}
              color={island.color}
              colorBody={island.colorBody}
              starsCount={island.stats.stars}
              lessonsCount={island.stats.lessons}
              timeCount={island.stats.time}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SummaryCard;
