import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

function Footer({ className }: { className?: string }) {
  return (
    <div
      className={`w-full bg-[#142548] text-white p-16 pb-36 pv:max-md:p-10 md:p-8 md:pb-16 flex flex-col items-start md:flex-row md:justify-between ${className}`}
    >
      {/* Links Section */}
      <div className="md:w-full flex pv:max-md:flex-wrap md:max-lg:flex-wrap justify-between text-[16px] md:max-xl:text-[12px] text-center md:text-left">
        {/* Logo */}
        <div className="mb-8 md:mb-0 flex flex-col w-1/2 md:max-lg:w-[20%] pv:max-md:w-full pv:max-md:items-start ">
          <img
            src="/logo/logo_footer.png"
            className="object-contain h-32 pv:max-md:h-[80px] md:max-xl:h-[95px]"
            alt="Why Kids Logo"
          />
        </div>
        {/* First Column */}
        <div className="mb-8 md:mb-0 flex flex-col w-1/2 md:max-lg:w-[20%] md:max-lg:items-start items-start pv:max-md:w-full pv:max-md:grid  pv:max-md:grid-cols-2 pv:max-md:justify-items-start pv:max-md:pl-0 pv:max-md:items-start gap-y-3">
          <a className="font-bold uppercase" href="#">
            Về chúng tôi
          </a>
          <a className="font-bold uppercase" href="#">
            Về Whykids
          </a>
          <a className="font-bold uppercase" href="/service-package">
            Gói dịch vụ
          </a>

          <a className="font-bold uppercase" href="/library-introduction">
            Thư viện học liệu
          </a>
          <a className="font-bold uppercase" href="/tin-tuc">
            Blog/ tin tức
          </a>
          <a className="font-bold uppercase" href="#">
            Hỗ trợ
          </a>
        </div>
        {/* Second Column (Contact Information) */}
        <div className="mb-8 md:mb-0 flex flex-col space-y-3 w-1/2 md:max-lg:w-[20%] pv:max-md:w-full pv:max-md:items-start pr-5">
          <a className="font-bold uppercase" href="#">
            Liên hệ
          </a>
          <p className="text-left">
            Địa chỉ: 24 Tôn Đức Thắng, Đống Đa, Hà Nội
          </p>
          <p>Email: hotline@whycorp.vn</p>
          <p>Hotline: 19006 38881</p>
          <div className="uppercase font-bold">
            Phương Thức Thanh toán
            <img
              // src="/logo/logo_vn_pay.png"
              src="/logo/logo_vnpay.png"
              className="object-contain h-24"
              alt=""
            />
          </div>
        </div>
        {/* Third Column (Legal & Social) */}
        <div className="flex flex-col space-y-3 w-1/2 md:max-lg:w-[20%] pv:max-md:w-full pv:max-md:items-start">
          <a className="font-bold uppercase" href="#">
            Điều khoản
          </a>
          <a href="/term">Điều khoản</a>
          <a href="/policy-2">Bảo mật thông tin</a>
          <a href="/policy-refund">Chính sách hoàn trả</a>
          <a href="/policy-payment-private">Chính sách bảo mật thanh toán</a>
          <a href="/payment-instruction">Hướng dẫn thanh toán</a>

          <div className="mt-4 flex flex-col">
            <span className="font-bold uppercase mb-2">
              Kết nối với chúng tôi
            </span>
            <div className="flex justify-start space-x-2">
              <div className="rounded-full bg-white p-2">
                <FaFacebook color="#142548" size={20} />
              </div>
              <div className="rounded-full bg-white p-2">
                <FaInstagram color="#142548" size={20} />
              </div>
              <div className="rounded-full bg-white p-2">
                <FaYoutube color="#142548" size={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
