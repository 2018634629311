const PaymentTerm = {
    payments_status_success_desc: "Giao dịch của bạn đã thành công",
  payments_status_thankyou: "Cảm ơn!",
  payments_status_appreciate:
    "Chúng tôi đánh giá cao hoạt động kinh doanh của bạn và hy vọng bạn thích sản phẩm mới của mình.",
  payments_status_home: "Trang chủ",
  payments_status_success: "Thanh toán thành công",
  payments_status_warning: "Giao dịch đáng ngờ",
  payments_status_warning_desc:
    "Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường)",
  payments_status_incomplete: "Thanh toán không thành công",
  payments_status_bankOutOfOrder: "Ngân hàng thanh toán đang bảo trì",
  payments_status_AccNotInternetBanking:
    "Tài khoản của quý khách chưa đăng ký dịch vụ InternetBanking tại ngân hàng",
  payments_status_AccAuthInforWrong3Times:
    "Xác thực sai thông tin tài khoản quá số lần quy định",
  payments_status_paymentExpired:
    "Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch",
  payments_status_AccIsBlocked: "Tài khoản của quý khách bị khóa",
  payments_status_WrongOTP:
    "Sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch",
  payments_status_TransCancelled: "Giao dịch đã được hủy",
  payments_status_AccNotEnounghBalance:
    "Quý khách không đủ số dư để thực hiện giao dịch",
  payments_status_MaxTransCapPerDay:
    "Tài khoản của quý khách đã vượt quá hạn mức giao dịch trong ngày",
  payments_status_PaymentAuthWrong3Times:
    "Sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch",
  payments_status_UnknowErrContactAdmin:
    "Có lỗi xảy ra, xin quý khách vui lòng liên hệ hotline 1900638881 để được giải quyết",
  payments_status_continueRegister: "Tiếp tục đăng ký ?",
  payments_status_tksForReg:
    "Cảm ơn bạn đã đăng ký, chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất",
  payments_status_DONE: "Hoàn tất",
  pu_course_detail_addToCart: "Thêm vào giỏ hàng",
  payments_status_bookingSuccess_cash: "Đăng ký thành công",
  payments_status_bookingSuccess_cash_desc:
    "Thanh toán tại quầy thật sớm để hoàn tất đăng ký nhé",
}

export default PaymentTerm;