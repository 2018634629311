import React, { useState } from "react";

interface MenuItem {
  key: string;
  label: string;
}

interface MenuLibraryProps {
  items: MenuItem[]; // Accept items as a prop
  onMenuClick: (key: string, index: number) => void;
  selectedKey: string;
}

const DropdownMenu: React.FC<MenuLibraryProps> = ({
  items,
  onMenuClick,
  selectedKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    "Chọn menu"
  );

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (key: string, label: string, index: number) => {
    setSelectedItem(label);
    setIsOpen(false);
    onMenuClick(key, index); // Trigger the callback
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={handleToggle}
        className="text-blue-500 bg-white font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center w-full"
        type="button"
      >
        {selectedItem}
        <svg
          className="w-2.5 h-2.5 ml-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white rounded-lg shadow-lg">
          <ul
            className="w-full bg-white rounded-lg font-semibold text-[11px] text-[#65AFFF]"
            style={{
              boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)", // A lighter shadow for a smoother appearance
            }}
          >
            {items.map((item, index) => (
              <li
                key={item.key}
                onClick={() => handleSelect(item.key, item.label, index)} // Pass key, label, and index
                className={`${index === 0 ? "rounded-t-lg " : "rounded-[0px]"}
                ${index === items.length - 1 ? "rounded-b-lg" : "rounded-[0px]"}
                cursor-pointer font-semibold  border-b-[1px] py-2 px-7 border-gray-200 transition-colors duration-300 ${
                  selectedKey === item.key
                    ? "bg-[#D5EAFE]"
                    : "bg-white hover:bg-[#D5EAFE]"
                }`}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
