import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Blog } from "../../../pages/BlogAndNews";
import { useNavigate } from "react-router-dom";

const LargeNewsCard = ({ blog }: { blog: Blog }) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  return (
    <div
      className="bg-white rounded-[30px] cursor-pointer	"
      onClick={() => {
        navigate(`../tin-tuc/${blog.path}`, {
          state: { dataDetail: blog },
        });
      }}
    >
      <img
        src={blog.titlepic || "default-image-url.jpg"}
        alt={blog.title || "Blog image"}
        className="bg-gray-400 w-full object-cover h-[519px] pv:max-lg:h-[216px] rounded-t-[30px]"
      />
      <div className="p-7 rounded-b-[25px]">
        <p className="font-bold text-[35px] pv:max-lg:text-[20px] text-justify uppercase leading-[36px]">
          {blog.title || "Untitled"}
        </p>
        <p className="font-medium text-justify my-5 pv:max-lg:text-[14px]">
          {blog.shortdescription || "Untitled"}
        </p>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-10">
            <div className="flex items-center gap-2">
              <img
                src="library_img/icon/Clock.png"
                className="w-[25px] pv:max-lg:w-[16px] object-contain"
                alt=""
              />
              <div className="pv:max-lg:text-[12px]">{formatDate(blog.createdAt)}</div>
            </div>
            {/* <div className="flex items-center gap-2">
              <img
                src="library_img/icon/Eye.png"
                className="w-[25px] object-contain"
                alt=""
              />
              <div>{blog.view}</div>
            </div> */}
          </div>
          <div
            className="flex items-center gap-3 pv:max-lg:gap-[0px]"
            onClick={() => {
              navigate(`../tin-tuc/${blog.path}`, {
                state: { dataDetail: blog },
              });
            }}
          >
            <p className="text-[#F56177] font-bold text-[20px] pv:max-lg:text-[12px]">xem thêm</p>
            <IoIosArrowForward className="text-[#F56177] text-[20px]" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LargeNewsCard;
