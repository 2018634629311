import axios from "axios";
import { API_URL } from "../config";

export const refreshTokenSchool = async (accessToken: String, refreshToken: String) =>{
    try {        
        const response = await axios.post(`${API_URL}/auth/token/refresh`, {
            accessToken:accessToken, 
            refreshToken:refreshToken,
        });
        return response.data;
    }
    catch (error) {
        return error;
    }
}