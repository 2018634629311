import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";
import { Blog } from "../BlogAndNews";
import { RichTextReadOnly } from "mui-tiptap";
import useExtensions from "../../components/Common/blogAndNews/useExtensions";
import {
  getAllBlog,
  getBlogDetail,
  getBlogDetailByKey,
} from "../../services/blog.service";
import { Skeleton } from "antd";
import { IoIosArrowForward } from "react-icons/io";
import ManyPeopleReadCard from "../../components/Common/blogAndNews/manyPeopleReadCard";
import FooterBlog from "../../components/Common/blogAndNews/footerBlog";

function BlogAndNewsDetail() {
  const location = useLocation();
  const dataDetail = location.state?.dataDetail?._id;
  const [data, setData] = useState<Blog | null>(null);
  const [blogData, setBlogData] = useState<Blog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const lastPart = window.location.pathname.split("/").pop() || "";

  const topReading = useMemo(
    () =>
      (blogData || [])
        .filter((blog) => blog.isPopular && blog.isPublic)
        .sort((a, b) => b.view - a.view)
        .slice(0, 5),
    [blogData]
  );

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  const extensions = useExtensions({
    placeholder: "Add your own content here...",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (dataDetail) {
          const response = await getBlogDetail(dataDetail);
          setData(response?.data);
        } else {
          const response = await getBlogDetailByKey(lastPart);
          setData(response?.data);
        }
        // Load the blog data for "Nhiều người đọc"
        const allBlogs = await getAllBlog();
        setBlogData(allBlogs?.data || []);
      } catch (error) {
        console.error("Error fetching blog details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dataDetail, lastPart]);

  useEffect(() => {
    const defaultDescription =
      "Whykids ứng dụng cung cấp các bài học và hoạt động sáng tạo về Toán, Tiếng Việt, Kỹ năng sống giúp trẻ phát triển kỹ năng mềm và kiến thức học tập toàn diện.";

    document.title =
      data?.titleSite || "WhyKids - Nền tảng giáo dục toàn diện cho thế hệ mới"; // Update page title based on blog title
    console.log("titlesite", data?.titleSite);
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        data?.descriptionSite || defaultDescription
      );
    }

    // Cleanup function to restore the original title and description when the component unmounts
    return () => {
      document.title = "WhyKids - Nền tảng giáo dục toàn diện cho thế hệ mới";
      if (metaDescription) {
        metaDescription.setAttribute("content", defaultDescription);
      }
    };
  }, [data?.title]);

  return (
    <div className="bg-[#F8F8F8] w-full">
      <Header searchTextChangeColor={true} />
      <div className="flex justify-center">
        <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] mb-10">
          {loading ? (
            <Skeleton active />
          ) : (
            <div className="w-[100%] text-[#122548] rounded-[30px] flex justify-between pv:max-lg:flex-col">
              <div className="w-[65%] pv:max-lg:w-full p-10 bg-white rounded-[30px]">
                <div className="flex flex-wrap font-medium text-[20px] items-center gap-3 ">
                  <Link to="/">Trang chủ</Link>
                  <IoIosArrowForward className="text-[#F56177] text-[20px]" />
                  <Link to="/tin-tuc">Blog/Sự kiện</Link>
                  <IoIosArrowForward className="text-[#F56177] text-[20px]" />
                  <p>{data?.title}</p>
                </div>
                {/* Title */}
                <h1 className="text-left my-10 font-bold uppercase text-[40px] pv:max-lg:text-[20px]">
                  {data?.title || "Blog Title"}
                </h1>
                {/* Date */}
                <div className="w-full flex justify-between text-[20px] pv:max-lg:text-[14px]">
                  <div className="flex items-center gap-2 ">
                    <img
                      src="/library_img/icon/Clock.png"
                      className="w-[25px] pv:max-lg:w-[14px] object-contain"
                      alt=""
                    />
                    <div>
                      {data?.updatedAt
                        ? formatDate(data.updatedAt)
                        : "Unknown Date"}
                    </div>
                  </div>
                </div>
                {/* Content */}
                <div className="flex flex-col mt-10 items-center justify-center">
                  <div className="w-[90%] gap-10 flex flex-col items-center">
                    <RichTextReadOnly
                      content={data?.longdescription || ""}
                      extensions={extensions}
                    />
                  </div>
                </div>
                {/* Author Info */}
                <div className="w-full h-[1px] my-10 bg-[#142548]" />
                <div className="flex justify-between items-end space-x-4">
                  <div className="h-[91px] w-[91px]  bg-gray-400 rounded-full" />
                  <div className="w-[90%] text-[20px] pv:max-lg:text-[20px]">
                    <p className="font-bold">Tên tác giả</p>
                    <p className="font-normal">
                      {/* Mô tả: Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit. Donec et mi volutpat, consectetur tellus eget,
                      sollicitudin leo. */}
                      Why Kids
                    </p>
                  </div>
                </div>
              </div>
              {/* Right section (Top 5 Blogs by View Count) */}
              <div className="w-[32%] pv:max-lg:w-full">
                <div className="w-full bg-white rounded-[30px]  p-10">
                  <p className="font-bold text-[35px] pv:max-lg:text-[25px]">
                    Nhiều người đọc
                  </p>
                  {topReading.length <= 0 ? (
                    <div className="flex items-center gap-2">
                      <h1 className="text-[18px] text-slate-300 font italic">
                        Hiện tại chưa có Blog/ Tin tức nhiều người đọc nào !
                      </h1>
                    </div>
                  ) : (
                    topReading.map((blog, index) => (
                      <ManyPeopleReadCard
                        key={blog._id}
                        blog={blog}
                        isLast={index === topReading.length - 1}
                      />
                    ))
                  )}
                </div>
                {/* <div className="bg-gray-600 mt-5 w-full h-[360px] rounded-[30px]" /> */}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* footer */}
      <div className="flex justify-center mb-16">
        <FooterBlog />
      </div>
      <Footer />
    </div>
  );
}

export default BlogAndNewsDetail;
