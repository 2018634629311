import React, { useState } from 'react';
import { getDataTest } from '../../../services/authSchool.service';

// Kiểu props cho các thuộc tính không bắt buộc
interface TooltipButtonProps {
  tooltipText?: string;
  imageUrl?: string;
}

const FloatingButton: React.FC<TooltipButtonProps> = ({ tooltipText = "Chat ngay cùng Whykids", imageUrl }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleLogin = async (e: React.FormEvent)  => {
    e.preventDefault();
    const data2= await getDataTest();
  };

  return (
    <div style={styles.container}>
      {/* Tooltip */}
      {showTooltip && (
        <div style={styles.tooltip}>
          
          <div className=" w-[auto] flex h-12 bg-[#142548] items-center justify-center rounded-full px-8">
              <p className=" text-[#FFFFFF] text-[20px] font-semibold">
              {tooltipText}
              </p>
            </div>
        </div>
      )}

      {/* Floating Button */}
      <button
        style={styles.fab}
        onClick={handleLogin}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img src="logo/Chat_Conversation_Circle.png" alt="Floating Button" style={styles.img} />
      </button>
    </div>
  );
};

// CSS styles
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'row', // Đặt tooltip ở bên trái nút
    alignItems: 'center',
  },
  fab: {
    backgroundColor: '#65AFFF',
    borderRadius: '50%',
    width: '56px',
    height: '56px',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
  },
  img: {
    width: '36px',  // Kích thước ảnh bên trong nút
    height: '36px',
  },
  tooltip: {
    marginRight: '4px',
    color: 'white',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
};

export default FloatingButton;
