import React from 'react';
import LoginPage from '../login/login';
import { Navigate } from 'react-router-dom';

// Giả sử có một hàm để kiểm tra người dùng đã đăng nhập hay chưa
const isAuthenticated = (): boolean => {
  // Kiểm tra trạng thái đăng nhập của người dùng
  // Có thể là kiểm tra token trong localStorage, session, v.v.
  return !!sessionStorage.getItem('accessTokenUser');
};

interface PrivateRouteProps {
  component: React.FC;
}

// Tạo PrivateRoute để kiểm tra quyền truy cập
const LoginUserRoute = () => {
  return isAuthenticated() ?  <Navigate to="/account" /> :<LoginPage/> ;
};

export default LoginUserRoute;