import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./controllers/route";
import { GameProvider } from "./contexts/gameContext";
import { UserProvider } from "./contexts/userContext";
// import { PaymentProcessProvider } from './pages/CheckOutOrder/paymentHandle';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GameProvider>
      <UserProvider>
        {/* <PaymentProcessProvider> */}
        <RouterProvider router={appRouter} />
        {/* </PaymentProcessProvider> */}
      </UserProvider>
    </GameProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
