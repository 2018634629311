import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Common/footer";
import Header from "../../components/Common/header/header";
import OptionPaymentRadioButton from "../../components/Common/payment_components/optionPaymentRadioButton";
import {
  paymentRequestUser,
  paymentRequestUserNoAuth,
} from "../../services/userServices/paymentUser.service";
import PackageRadioButton2 from "../../components/Common/payment_components/packageRadioButton2";
import { ServicePackageInfo } from "../../components/Common/service_package_component/servicePackageTypes";
import { getAllPackageService } from "../../services/servicePackage.service";
import { packageAttributes } from "../servicePackage";
import { Skeleton } from "antd";
import ConfirmPaymentModal from "../../components/Common/payment_components/ConfirmPaymentModal";
import { getAccountExists } from "../../services/userServices/authUser.service";
import { checkRefCode } from "../../services/refcode.service";

interface DiscountModel {
  key: string;
  value: number;
}
interface PaymentConfirmData {
  customerName: string;
  customerPhoneNumber: string;
}

const isAuthenticated = (): boolean => {
  // Kiểm tra trạng thái đăng nhập của người dùng
  // Có thể là kiểm tra token trong localStorage, session, v.v.
  return !!sessionStorage.getItem("accessTokenUser");
};

function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function CheckOutOrder() {
  const [selectedPriority, setSelectedPriority] = useState<number>(1); // Initial state is the number 1
  const [servicePackageData, setServicePackageData] = useState<
    ServicePackageInfo[]
  >([]);
  const [selectedPackageID, setSelectedPackageID] = useState<string>("");
  const [index, setIndex] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [inputCode, setInputCode] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [paymentConfirmData, setPaymentConfirmData] =
    useState<PaymentConfirmData>({
      customerName: "",
      customerPhoneNumber: "",
    });
  const [isReadOnlyRefCode, setIsReadOnlyRefCode] = useState(false);

  // const [statusRefCode, setStatusRefCode] = useState("");
  const [refName, setRefName] = React.useState<string | null>(null);
  const [refCode, setRefCode] = useState<string | null>(null);
  const [correctRefCode, setCorrectRefCode] = useState("");
  const [hasUsed, setHasUsed] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState<DiscountModel[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<string>("");
  const [selectedPrice, setSelectedPrice] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();
  const paymentAccess = sessionStorage.getItem("accessTokenUser");

  const allPackages = location.state?.allPackages || null;
  const indexPassed = location.state?.index + 1 || 1;

  useEffect(() => {
    setIndex(indexPassed);
    // handle data
    if (allPackages) {
      setServicePackageData(allPackages);
    } else {
      const fetchData = async () => {
        const packages = await getAllPackageService();

        const mergedData = packages.data.map((pkg: ServicePackageInfo) => ({
          ...pkg,
          packageColor:
            packageAttributes[pkg.priority]?.packageColor ?? "#FFFFFF",
          orignalPrice: packageAttributes[pkg.priority]?.orignalPrice ?? "",
          img: packageAttributes[pkg.priority]?.img ?? "",
        }));
        const sortedData = mergedData.sort(
          (a: { price: number }, b: { price: number }) => a.price - b.price
        );
        setServicePackageData(sortedData);
      };
      fetchData();
    }

    const queryParams = new URLSearchParams(location.search);
    const refcode = queryParams.get("refcode") || "";
    if (refcode) {
      setIsReadOnlyRefCode(true);
    } else {
      setIsReadOnlyRefCode(false);
    }
    handleGetRequestAffiliateData(refcode);
    // console.log(refcode, "param refcode");
  }, [allPackages, location, navigate]);

  const handleGetRequestAffiliateData = async (ref: string) => {
    try {
      if (ref.trim().length == 6) {
        const data = await checkRefCode(ref);
        const refName = `${data.data.first_name} ${data.data.last_name}`;
        // console.log("Affiliate name:", refName);
        setRefName(refName);
        setInputCode(data.data.personal_referal_code);
        setRefCode(data.data.personal_referal_code);
        setCorrectRefCode(data.data.personal_referal_code);
      } else {
        setRefName("Mã người giới thiệu không tồn tại");
      }
    } catch (error) {
      setRefName("Mã người giới thiệu không tồn tại");
      setInputCode(ref);
    }
  };

  // Automatically select the first package when data is available
  useEffect(() => {
    if (servicePackageData.length > 0 && servicePackageData[index - 1]) {
      const firstPackage = servicePackageData[index - 1];
      handlePriorityChange(
        index,
        firstPackage.title,
        firstPackage.price,
        firstPackage.title,
        firstPackage.ID
      );
    }
  }, [servicePackageData, index]);

  const handleInputEmailChange = (e: any) => {
    setInputEmail(e.target.value);
  };

  const validateEmailOrPhone = (value: string) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const phoneRegex = /^\d{10}$/;
    return emailRegex.test(value) || phoneRegex.test(value);
  };

  const paymentGate = () => {
    isAuthenticated() ? handlePayment() : openConfirmModal();
  };
  const openConfirmModal = async () => {
    if (!inputEmail || !validateEmailOrPhone(inputEmail)) {
      alert("Bạn chưa nhập Email/SĐT hợp lệ");
      return;
    }

    const accountStatus = await getAccountExists(inputEmail);
    if (accountStatus && accountStatus.status === "OK") {
      const customerName = accountStatus.data.name
        ? accountStatus.data.name
        : `${inputEmail} (Tài khoản đang dùng tên mặc định)`;

      setPaymentConfirmData({
        customerName: customerName,
        customerPhoneNumber: inputEmail,
      });
    } else {
      setPaymentConfirmData({
        customerName: `Hệ thống sẽ tự động tạo tài khoản với email/số điện thoại ${inputEmail}. \n Quý khách hãy liên hệ affiliate hoặc hotline của chúng tôi để nhận được mã truy cập.`,
        customerPhoneNumber: inputEmail,
      });
    }

    setIsModalVisible(true);
  };

  const closeConfirmModal = () => {
    setIsModalVisible(false);
  };
  const handlePayment = async () => {
    const refCode = correctRefCode;
    // const refCode = "G4S64S";
    // console.log("2220", refCode);
    console.log(window.location.href.split("?")[0]);
    //  ********
    if (paymentAccess == null) {
      var url = window.location.href;
      if (url.indexOf("?") > -1) {
        url = window.location.href.split("?")[0];
      }
      const returnUrl = url + "/paymentprocess";

      const selectedPackage = servicePackageData[selectedPriority as number];
      console.log(selectedPackageID);
      if (isValidEmail(inputEmail)) {
        const data = await paymentRequestUserNoAuth(
          selectedPackageID,
          returnUrl,
          inputEmail,
          undefined,
          refCode ?? undefined
        );
        if (data.msg == "Success") {
          sessionStorage.setItem("paymentProcess", "true");
          window.open(data.data);
          // Close the current window
          navigate("/paymentprocess");
        }
      } else {
        const data = await paymentRequestUserNoAuth(
          selectedPackageID,
          returnUrl,
          undefined,
          inputEmail,
          refCode ?? undefined
        );
        if (data.msg == "Success") {
          sessionStorage.setItem("paymentProcess", "true");
          window.open(data.data);
          // Close the current window
          navigate("/paymentprocess");
        }
      }
    } else {
      var url = window.location.href;
      if (url.indexOf("?") > -1) {
        url = window.location.href.split("?")[0];
      }
      const returnUrl = url + "/paymentprocess";
      const selectedPackage = servicePackageData[selectedPriority as number];
      const data = await paymentRequestUser(
        selectedPackageID,
        returnUrl,
        refCode ?? undefined
      );
      if (data.msg == "Success") {
        sessionStorage.setItem("paymentProcess", "true");
        window.open(data.data);
        // Close the current window
        navigate("/paymentprocess");
      }
    }
  };

  const handlePriorityChange = (
    priority: number,
    label: string,
    price: number,
    title: string,
    ID: string
  ) => {
    setSelectedPriority(priority);
    setSelectedLabel(label);
    setSelectedPrice(price);
    setSelectedPackageID(ID);

    const totalDiscount = discounts.reduce(
      (acc, discount) => acc + discount.value,
      0
    );
    const newTotalPrice = Math.max(price - totalDiscount, 0);
    setTotalPrice(newTotalPrice);

    // console.log(
    //   `Selected Package: ${label}, Price: ${price}`,
    //   `Title: ${title}`,
    //   `ID: ${ID}`
    // );
  };

  const applyRefCode = async () => {
    if (inputCode.trim().length !== 6)
      return alert("Mã Giới Thiệu Phải Có 6 Ký Tự");
    handleGetRequestAffiliateData(inputCode);
  };

  return (
    <div className="bg-[#F8F8F8] w-full">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pt-48 pv:max-ph:pt-32 ph:max-md:pt-40 ">
        <div className="w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] mb-16">
          <div className="flex pv:max-xl:flex-col pv:max-xl:items-center pv:max-xl:gap-5 h-full w-full justify-between">
            {/* left section */}
            <div className="w-[75%]  pv:max-xl:w-[100%] bg-white rounded-[30px] p-10">
              <div className="gap-6 flex flex-col">
                <p className="font-bold text-[44px] pv:max-md:text-[25px] text-[#142548]">
                  Thông tin liên hệ
                </p>
                {paymentAccess != null ? (
                  <p className="font-semibold text-[23px] pv:max-md:text-[20px] text-[#142548]">
                    Hệ thống sẽ ghi nhận tự động dựa vào đăng nhập của quý khách
                  </p>
                ) : (
                  <div>
                    {/* <div>
                      <p className="font-semibold text-[23px] text-[#142548]">
                        Họ và tên
                      </p>
                      <input
                        className="w-full h-12 border-2 border-[#142548] rounded-full mt-1 sm:mt-3 px-4  text-[23px] text-[#142548] font-semibold outline-none"
                        placeholder="Họ và tên"
                      ></input>
                    </div> */}
                    <div>
                      <p className="font-semibold mt-5 text-[23px] text-[#142548]">
                        Số điện thoại/ Email:
                      </p>
                      <input
                        className="w-full h-12 border-2 border-[#142548] rounded-full mt-1 sm:mt-3 px-4 text-[23px] text-[#142548] font-semibold outline-none"
                        placeholder="Số điện thoại/ Email"
                        value={inputEmail}
                        onChange={handleInputEmailChange}
                      />
                    </div>
                  </div>
                )}

                <p className="font-bold text-[44px] pv:max-md:text-[25px] text-[#142548]">
                  Gói đã chọn
                </p>
                <div>
                  {servicePackageData.length > 0 ? (
                    <PackageRadioButton2
                      selectedValue={selectedPriority}
                      onChange={handlePriorityChange}
                      servicePackageData={servicePackageData}
                    />
                  ) : (
                    <div className=" w-full flex justify-start items-center">
                      <Skeleton active className="w-[100%]" />
                    </div>
                  )}
                </div>
                <p className="font-bold text-[44px] pv:max-md:text-[25px] text-[#142548] capitalize">
                  Mã Người giới thiệu
                </p>
                <div className="flex justify-between border-2 border-[#142548] rounded-full px-10 py-2 pv:max-md:py-[5px] pv:max-md:px-[20px]">
                  <input
                    className="w-[80%] text-[20px] pv:max-md:text-[15px] pv:max-md:w-[50%] font-semibold uppercase pl-3 text-[#142548] select-none focus:outline-none"
                    placeholder="Mã giới thiệu"
                    value={inputCode}
                    onContextMenu={(e) => e.preventDefault()}
                    onCopy={(e) => e.preventDefault()}
                    onChange={(e) => setInputCode(e.target.value)}
                    readOnly={isReadOnlyRefCode}
                    maxLength={6}
                  />

                  <button
                    className="bg-[#142548] w-auto font-bold py-1 px-5 rounded-[33px] text-white"
                    onClick={applyRefCode}
                  >
                    <p className="text-[20px] pv:max-md:text-[12px]">Áp dụng</p>
                  </button>
                </div>

                {inputCode.length == 6 ? (
                  <p className="font-medium text-[20px] pv:max-md:text-[12px] text-[#142548] capitalize">
                    {refName}
                  </p>
                ) : (
                  <p className="font-medium text-[20px] pv:max-md:text-[12px] text-[#142548] capitalize"></p>
                )}

                {/* 
                <p className="font-bold text-[44px] pv:max-md:text-[25px] text-[#142548]">
                  Phương thức thanh toán
                </p>
                <OptionPaymentRadioButton /> */}
              </div>
            </div>
            {/* right section */}
            <div className="w-[23%]  pv:max-xl:w-[100%] bg-white rounded-[30px] p-10">
              <div className="gap-6 flex flex-col">
                <p className="font-semibold text-[45px] pv:max-md:text-[25px] text-[#142548]">
                  Đơn hàng
                </p>
                <div>
                  <div className="w-full flex justify-between">
                    <p className="font-semibold text-[23px] pv:max-md:text-[15px] text-[#142548]">
                      {selectedLabel}
                    </p>
                    <p className="font-medium text-[23px] pv:max-md:text-[15px] text-[#142548]">
                      {selectedPrice.toLocaleString("de-DE")}
                    </p>
                  </div>
                </div>
                {/* <div>
                  <p className="font-semibold text-[23px] pv:max-md:text-[25px] text-[#142548]">
                    Giảm giá
                  </p>
                  {discounts.length == 0 && (
                    <p className="text-[#73777f] italic">
                      Không có mã giảm giá nào đang được áp dụng
                    </p>
                  )}
                  {discounts.map((discount, index) => (
                    <div key={index} className="w-full flex justify-between">
                      <p className="font-normal text-[23px] pv:max-md:text-[15px] text-[#142548]">
                        {discount.key}
                      </p>
                      <p className="font-medium text-[23px] pv:max-md:text-[15px] text-[#F56177]">
                        {`-${discount.value.toLocaleString("vi-VN")}`}
                      </p>
                    </div>
                  ))}
                </div> */}
                {refCode === "" ||
                refCode === null ||
                refName == "Mã người giới thiệu không tồn tại" ? (
                  <div></div>
                ) : (
                  <div>
                    <p className="font-semibold text-[23px] pv:max-md:text-[25px] text-[#142548]">
                      Mã giới thiệu
                    </p>
                    <div className="w-full flex justify-between">
                      <p className="font-normal text-[23px] pv:max-md:text-[15px] text-[#142548]">
                        {refCode}
                      </p>
                      <p className="font-medium text-[23px] pv:max-md:text-[15px] text-[#F56177]">
                        {refName}
                      </p>
                    </div>
                  </div>
                )}

                {/* Total amount after discounts */}
                <div className="w-full flex justify-between align-bottom items-end">
                  <p className="font-semibold text-[34px] pv:max-md:text-[26x] text-[#142548]">
                    Tổng cộng
                  </p>
                  <p className="font-medium text-[25px] text-[#142548]">
                    {totalPrice.toLocaleString("de-DE")}
                  </p>
                </div>

                <div className="flex justify-end">
                  <button
                    className="bg-[#142548] font-bold py-1 px-5 rounded-[33px] text-white"
                    onClick={paymentGate}
                  >
                    <p className="text-[20px] pv:max-md:text-[14px]">
                      Thanh toán
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {isModalVisible && (
        <div className="">
          <ConfirmPaymentModal
            handleCancel={closeConfirmModal}
            handlePayment={handlePayment}
            customerName={paymentConfirmData.customerName}
            customerPhoneNumber={paymentConfirmData.customerPhoneNumber}
          />
        </div>
      )}
    </div>
  );
}

export default CheckOutOrder;
