import { useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const ImagePolicy05 =
  process.env.PUBLIC_URL + "/policyGuide/policy_guide_05.png";
const ImagePolicy07 =
  process.env.PUBLIC_URL + "/policyGuide/policy_guide_07.png";
const ImagePolicy08 =
  process.env.PUBLIC_URL + "/policyGuide/policy_guide_08.png";
const ImagePolicy09 =
  process.env.PUBLIC_URL + "/policyGuide/policy_guide_09.png";
const ImagePolicy10 =
  process.env.PUBLIC_URL + "/policyGuide/policy_guide_10.png";

const PayWithVNPayQR = ({ t }: any) => {
  const [stage, setStage] = useState(0);

  const handleOnChangeStage = (stage: any) => {
    setStage(stage);
  };

  const handleOnChangeImage = () => {
    switch (stage) {
      case 0:
        return ImagePolicy07;
      case 1:
        return ImagePolicy08;
      case 2:
        return ImagePolicy09;
      case 3:
        return ImagePolicy10;
      default:
        return ImagePolicy05;
    }
  };

  const handleNextStage = () => {
    setStage(stage + 1);
  };

  const handlePreviousStage = () => {
    setStage(stage - 1);
  };

  return (
    <div className="px-8 py-12 w-fit rounded-xl bg-white flex flex-col md:w-[100%] lg:justify-center lg:items-center lg:flex-row drop-shadow-2xl">
      <div className="w-full flex flex-col justify-center md:flex-row md:justify-between md:items-center lg:w-[40%] lg:mr-6 ">
        <IoIosArrowBack
          className={`text-secondary2 p-2 w-32 h-12 cursor-pointer hidden rounded-lg md:block 2xl:p-0 2xl:h-20 2xl:w-36 hover:bg-slate-100 ${
            stage == 0 ? "invisible	" : ""
          }`}
          onClick={() => {
            handlePreviousStage();
          }}
        />
        <img
          src={handleOnChangeImage()}
          alt="VN PAY-QR"
          className="mx-2 scale-75 lg:scale-100"
        />
        <IoIosArrowForward
          className={`text-secondary2 p-2 w-32 h-12 cursor-pointer hidden rounded-lg md:block 2xl:p-0 2xl:h-20 2xl:w-36 hover:bg-slate-100 ${
            stage == 3 ? "invisible	" : ""
          }`}
          onClick={() => {
            handleNextStage();
          }}
        />
      </div>
      <div className="lg:grow-1 flex flex-col justify-between items-start">
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 0
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(0);
            }}
          >
            {1}
          </span>
          <div
            className={`flex flex-col justify-center ${
              stage == 0 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 0 ? "mb-4" : ""}`}>
              Xác nhận giỏ hàng
            </span>

            {stage == 0 && (
              <span className="w-[220px] md:w-[520px] lg:w-[350px]  text-justify text-base font-bold text-black">
                Bạn chọn sản phẩm dịch vụ rồi chọn Thanh toán ngay hoặc Đặt
                hàng. Tại trang thanh toán, quý khách vui lòng kiểm tra sản phẩm
                đã đặt, điền thông tin người nhận hàng, chọn phương thức thanh
                toán VN PAY và nhấn nút Đặt hàng ngay.
              </span>
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 1
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(1);
            }}
          >
            {2}
          </span>
          <div
            className={`w-[220px] md:w-[520px] lg:w-[350px] flex flex-col justify-center ${
              stage == 1 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 1 ? "mb-4" : ""}`}>
              Chọn phương thức thanh toán
            </span>

            {stage == 1 && (
              <span className="whitespace-normal text-justify text-base font-bold text-black">
                Màn hình thanh toán chuyển sang giao diện cổng thanh toán VN
                PAY. Chọn phương thức Ứng dụng thanh toán hỗ trợ VN PAY-QR
              </span>
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 2
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(2);
            }}
          >
            {3}
          </span>
          <div
            className={`w-[220px] md:w-[520px] lg:w-[350px] flex flex-col justify-center ${
              stage == 2 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 2 ? "mb-4" : ""}`}>Quét mã QR</span>

            {stage == 2 && (
              <span className="whitespace-normal text-justify text-base font-bold text-black">
                Hệ thống hiển thị mã QR kèm theo số tiền cần thanh toán. Vui
                lòng kiểm tra lại số tiền này. Sử dụng ứng dụng ngân hàng (theo
                danh sách), chọn Scan Code và quét mã QR trên màn hình thanh
                toán website.
                <br />
                <br />
                <em>
                  Lưu ý: Mã QR có hiệu lực trong 15 phút Để quá trình thanh toán
                  thành công, khách hàng vui lòng xem trước điều kiện và quét mã
                  trên điện thoại để sẵn sàng tránh trường hợp hết thời gian chờ
                  ảnh hưởng đến thanh toán và mã khuyến mãi.
                </em>
              </span>
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center">
          <span
            className={`w-[40px] mr-10 flex justify-center items-center cursor-pointer ${
              stage == 3
                ? "w-8 h-10 rounded-full text-white bg-[#0f2471] shadow-xl"
                : "w-8 h-10 border-2 border-[#0f2471] text-[#0f2471] rounded-full"
            }`}
            onClick={() => {
              handleOnChangeStage(3);
            }}
          >
            {4}
          </span>
          <div
            className={`w-[220px] md:w-[520px] lg:w-[350px] flex flex-col justify-center ${
              stage == 3 ? "text-xl font-bold text-[#0f2471]" : ""
            }`}
          >
            <span className={`${stage == 3 ? "mb-4" : ""}`}>
              Kiểm tra lại thông tin
            </span>

            {stage == 3 && (
              <span className="whitespace-normal text-justify text-base font-bold text-black">
                Kiểm tra thông tin, nhập mã giảm giá (nếu có) và hoàn tất thanh
                toán. Khi thanh toán hoàn tất, bạn sẽ nhận được thông báo xác
                nhận đơn hàng thành công trên website
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayWithVNPayQR;

// w-[40px] mr-10 w-10 h-8 rounded-full flex justify-center items-center text-white bg-[#0f2471]
