import React, { useRef, useState } from "react";
import { useGameContext } from "../../../contexts/gameContext";

interface LearningCardProps {
  lessonTitle?: string;
  videoURl?: string;
  titleVideo?: string;
  thumnail?: string;
  age?: string;
  thumnailGame?: string[]; // Assumed to be an array of image URLs
  colorCardLv1: string;
  colorCardLv2: string;
  colorCardLv3: string;
  handleClick?: (videoTime: number) => void;
}

const LearningCard: React.FC<LearningCardProps> = ({
  lessonTitle,
  videoURl,
  titleVideo,
  thumnail,
  age,
  thumnailGame = [],
  colorCardLv1,
  colorCardLv2,
  colorCardLv3,
  handleClick,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime] = useState(0);
  const { gamesData } = useGameContext();

  const capitalizeFirstLetter = (content?: string) =>
    content
      ? content.charAt(0).toUpperCase() + content.slice(1).toLowerCase()
      : "";

  const listGames = gamesData.filter((game) => thumnailGame.includes(game.key));

  // Handle right-click prevention on the video element
  const handleRightClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
  };

  // Wrapper to handle the click and send the video time to the parent component
  const handleClickWrapper = () => {
    if (handleClick) handleClick(currentTime);
  };

  // Dynamically set background color based on age
  const backgroundColor =
    age === "5-6" ? colorCardLv3 : age === "3-6" ? colorCardLv2 : colorCardLv1;

  return (
    <button
      onClick={handleClickWrapper}
      className="flex flex-col w-fit h-fit pv:max-md:w-[180px] pv:max-md:h-fit lg:max-xl:w-[225px] xl:max-xlmax:w-[270px] xlmax:max-dv:w-[295px] dv:max-dvmax:w-[305px] dvmax:max-dk:w-[32%] pv:max-md:rounded-[7px] rounded-[17px] transition-transform duration-300 px-6 py-4 pv:max-md:py-2 pv:max-md:px-2 lg:max-xl:px-3 lg:max-xl:py-2 hover:scale-110"
      style={{
        backgroundColor,
        boxShadow: "0 5px 10px rgba(0, 0, 0, 0.3)",
      }}
    >
      <div className="h-full">
        <p className="uppercase font-bold text-[#142548] pv:max-md:text-[8.35px] lg:max-xl:text-[10px] xl:max-xlmax:text-[12px] xlmax:max-dv:text-[14px] text-[19px] text-start">
          {lessonTitle}
        </p>
        <div className="flex gap-5 pv:max-md:gap-2 lg:max-xl:gap-3 mt-1">
          {/* Left - Video Section */}
          <div className="w-[210px] pv:max-md:w-[90px] lg:max-xl:w-[125px] xl:max-xlmax:w-[130px] xlmax:max-dv:w-[150px] dv:max-dvmax:w-[165px] dvmax:max-dk:w-[60%]">
            <div className="relative">
              <video
                onContextMenu={handleRightClick}
                ref={videoRef}
                className="h-[137px] pv:max-md:h-[60px] lg:max-xl:h-[80px] xl:max-xlmax:h-[100px] xlmax:max-dv:h-[110px] dv:max-dvmax:h-[115px] w-full rounded-[6px] pv:max-md:rounded-[2px] object-fill"
                controlsList="nodownload"
                poster={thumnail?.replace(
                  "http://34.49.7.176",
                  "https://storage.googleapis.com/why_kid_static_content"
                )}
              >
                <source
                  src={videoURl?.replace(
                    "http://34.49.7.176",
                    "https://storage.googleapis.com/why_kid_static_content"
                  )}
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="mt-3 pv:max-md:mt-1 lg:max-xl:mt-2 flex items-center gap-3 pv:max-md:gap-2 text-[#142548]">
              <p className="w-[80px] pv:max-md:text-[12px] lg:max-xl:text-[10px] xl:max-xlmax:text-[16px] xlmax:max-dv:text-[20px] dv:max-dvmax:text-[25px] text-[27px] font-[800]">
                {age}
              </p>
              <div className="h-[30px] w-[2px] pv:max-md:h-[10px] pv:max-md:w-[1px] bg-[#142548]" />
              <p className="w-[190px] pv:max-md:text-[5px] font-bold text-[12px] lg:max-xl:text-[8px] text-left line-clamp-2">
                {capitalizeFirstLetter(titleVideo)}
              </p>
            </div>
          </div>
          {/* Right - Image Grid Section */}
          <div className="w-[135px] pv:max-md:w-[58px] h-full items-center lg:max-xl:w-[65px] xl:max-xlmax:w-[80px] xlmax:max-dv:w-[80px] dv:max-dvmax:w-[75px] dvmax:max-dk:w-[28%]">
            <div className="h-[137px] pv:max-md:h-[62px] lg:max-xl:h-[80px] xl:max-xlmax:h-[100px] xlmax:max-dv:h-[110px] dv:max-dvmax:h-[115px] w-full grid grid-cols-2 gap-2 pv:max-md:gap-1 justify-items-center">
              {listGames.map((game, i) => (
                <div
                  key={i}
                  className="rounded-[6px] pv:max-md:rounded-[2px] overflow-hidden"
                >
                  <img
                    src={game.thumbnailUrl.replace(
                      "http://34.49.7.176",
                      "https://storage.googleapis.com/why_kid_static_content"
                    )}
                    alt={game.key}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
              {/* Placeholder for the case when there are fewer than 2 games */}
              {listGames.length < 2 && (
                <div className="h-[64.5px] w-[63.5px] pv:max-md:rounded-[2px] rounded-[6px] overflow-hidden" />
              )}
            </div>
            <p className="mt-5 font-bold pv:max-md:text-[5px] text-[12px] lg:max-xl:mt-[11px] pv:max-md:mt-[4px] xl:max-xlmax:mt-[8px] xlmax:max-dv:mt-[10px] dv:max-dvmax:mt-[10px] lg:max-xl:text-[8px] text-center text-[#142548] capitalize">
              Hoạt động hỗ trợ
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};

export default LearningCard;
