import React, { useEffect } from "react";
import CarouselHeader from "../../components/Common/home_components/carousel";
import Devider from "../../components/Common/devider";
import IslandInfo from "../../components/Common/home_components/island";
import HomeCard from "../../components/Common/home_components/homecard";
import BodyCarousel from "../../components/Common/home_components/body_carousel";
import Footer from "../../components/Common/footer";
import CharacterCarousel from "../../components/Common/home_components/character_carousel";
import { FloatButton } from "antd";
import FloatingButton from "../../components/Common/home_components/floatingButton";
import { getDataTest } from "../../services/authSchool.service";

function HomePage() {
  useEffect(() => {
    var video = document.getElementById("myvideo") as HTMLVideoElement;
    video.addEventListener("contextmenu", handlecontextmenu);
    return () => {
      document.removeEventListener("contextmenu", handlecontextmenu);
    };
  }, []);
  const handlecontextmenu = (e: MouseEvent) => {
    e.preventDefault();
    // alert('Right click disable');
  };

  return (
    <div className="">
      <CarouselHeader />
      {/* <div className="bg-white flex justify-around my-6">
        <RewardCard rewardName="abc" />
        <RewardCard rewardName="abc" />
        <RewardCard rewardName="abc" />
      </div>
     */}
      <IslandInfo />
      <BodyCarousel reverseRow={false} />
      <div className="mt-10"></div>
      <HomeCard
        urlLink="imageHome/home_img1.png"
        title="nội dung độc quyền"
        content="Kho tàng video và bài hát độc quyền của Whykids không chỉ mang đậm dấu ấn thương hiệu với nội dung giáo dục sáng tạo và an toàn, mà còn giúp trẻ học tập hiệu quả, ghi nhớ lâu dài, và phát triển toàn diện thông qua giai điệu và tình huống hấp dẫn."
        reverseRow={true}
      />
      <HomeCard
        urlLink="https://storage.googleapis.com/why_kid_static_content/WEBSITE/Trailer_Game.mp4"
        title="trải nghiệm số hóa"
        content="Với trải nghiệm học tập đa dạng và cá nhân hóa, Whykids khiến việc học trở nên thú vị hơn bao giờ hết, giúp trẻ em hứng thú và gắn bó lâu dài trong hành trình khám phá kiến thức."
        reverseRow={false}
      />
      {/* <CharacterCarousel
        // urlLink="character/tac_ke.png"
        content="Những nhân vật trong thế giới Whykids không chỉ mang lại niềm vui mà còn là những người bạn đồng hành trong hành trình giáo dục, với tính cách gần gũi, bối cảnh Việt Nam thân thuộc, và những câu chuyện gắn liền với cuộc sống hàng ngày của trẻ."
        reverseRow={true}
      /> */}
      <div className="w-full px-32 py-16 pv:max-md:px-5">
        <div className="flex flex-col justify-end w-[100%] gap-10 pv:max-md:w-full pv:max-md:text-center">
          <p className="uppercase text-center font-[800] lg:max-xl:text-[35px] xl:max-dvmax:text-[50px] dvmax:max-dh:text-[70px] tracking-wide text-[#142548] sm:max-md:text-3xl pv:max-md:text-[26px] md:max-lg:text-[40px]">
            Hệ Thống nhân vật đặc biệt
          </p>
          <p className="font-[500] text-justify text-[#142548] text-[20px] dvmax:max-dh:text-[25px] sm:max-md:text-xl md:max-lg:text-[14px]">
            Những nhân vật trong thế giới Whykids không chỉ mang lại niềm vui mà
            còn là những người bạn đồng hành trong hành trình giáo dục, với tính
            cách gần gũi, bối cảnh Việt Nam thân thuộc, và những câu chuyện gắn
            liền với cuộc sống hàng ngày của trẻ.
          </p>
        </div>
        <div className="mt-10">
          <img
            src="/imageHome/trung_thu.gif"
            className="w-full object-contain"
            alt=""
          />
        </div>
      </div>
      <Footer />
      <FloatingButton />
    </div>
  );
}

export default HomePage;
