import check from "../../assets/Library/check.png";

const ValueCard = ({
  imageURL,
  title,
  items,
  nextClick,
  prevClick,
}: {
  imageURL: string;
  title: string;
  prevClick: () => void;
  nextClick: () => void;
  items: Array<{
    title: string;
  }>;
}) => {
  return (
    <div className="flex pv:max-dv:flex-col pv:max-dv:items-center justify-between items-start w-full bg-red">
      <img
        className="items-center rounded-[30px] pv:max-md:w-full pv:max-md:object-contain pv:max-md:h-auto w-[408px] h-[391px] dv:max-dk:h-[460px] object-cover"
        src={imageURL}
        alt=""
      />

      <div className="flex flex-col justify-start h-auto  dv:max-dk:w-[60%] w-[70%] pv:max-lg:w-full">
        {/* Header Section with Title and Arrows */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-[60px] pv:max-sm:text-[30px] dv:max-dvmax:text-[70px] font-[800] text-[#142548]">
            {title}
          </h3>
          <div className="flex gap-5 pv:max-sm:gap-2">
            <img
              className="h-[48px] pv:max-sm:w-[26px] object-contain cursor-pointer"
              src="/library_img/icon/icon_back.png"
              alt="Previous"
              onClick={prevClick}
            />
            <img
              className="h-[48px] pv:max-sm:w-[26px] object-contain cursor-pointer"
              src="/library_img/icon/icon_next.png"
              alt="Next"
              onClick={nextClick}
            />
          </div>
        </div>

        {/* Item List Section */}
        <div className="grid grid-cols-2 pv:max-md:grid-cols-1 gap-6 pv:max-dv:gap-[4px]">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-start mb-6 pv:max-lg:mb-4"
            >
              <div className="w-[59px] pv:max-sm:w-[40px] h-auto bg-[#142548] rounded-md">
                <img
                  className="items-center object-contain"
                  src={check}
                  alt=""
                  style={{
                    width: "59px",
                  }}
                />
              </div>
              <p className="ml-6 font-[400] dv:max-dvmax:text-[26px] pv:max-sm:text-[14px] pv:max-sm:leading-[18px] text-[20px] w-[483px] leading-[26.31px]">
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValueCard;
