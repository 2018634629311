import { useEffect, useRef, useState } from "react";
import { useGameContext } from "../../../contexts/gameContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

interface ModalLessonProps {
  data?: any;
  isModalVisible: boolean;
  handleCancel: () => void;
  videoURl?: string;
  listThumnaiGamelURl?: string[];
  index?: number;
}

function ModalLesson({
  isModalVisible,
  handleCancel,
  listThumnaiGamelURl,
  data,
}: ModalLessonProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hasAlerted, setHasAlerted] = useState(false); // State to track if alert has been triggered
  const { gamesData } = useGameContext();

  const capitalizeFirstLetter = (content: string | undefined) => {
    return content ? content.charAt(0).toUpperCase() + content.slice(1) : "";
  };

  const listGames = gamesData.filter((game) =>
    listThumnaiGamelURl?.includes(game.key)
  );

  // Function to handle time updates
  const handleTimeUpdate = () => {
    if (videoRef.current && !hasAlerted) {
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      const percentageWatched = (currentTime / duration) * 100;
      if (percentageWatched >= 99) {
        // ***start integrate firebase analytics***
        logEvent(analytics, "web_lesson_view_completed", {
          lesson_name: data?.lessonTitle,
        });
        setHasAlerted(true);
      }
    }
  };

  const handleRightClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    event.preventDefault();
  };

  if (!isModalVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative pv:max-md:w-[330px] md:max-lg:w-[80%] lg:max-xl:w-[800px] xl:max-dv:w-[1150px] w-[1274px]">
        <div className="w-full h-[70%] flex justify-center rounded-[60px] pv:max-md:rounded-[13px]">
          <div className="text-[#142548] rounded-[30px] pv:max-md:rounded-[13px] p-10 pv:max-md:p-5 bg-white w-[100%] h-full">
            <p className="font-bold pv:max-md:text-[14px] md:max-lg:text-[50px] lg:max-xl:text-[50px] text-[67px] uppercase">
              {data?.lessonTitle}
            </p>
            <div className="flex justify-between mt-3">
              <div className="w-[60%]">
                <video
                  id="myVideo"
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate} // Add onTimeUpdate event
                  className="h-[430px] pv:max-md:h-[110px] lg:max-xl:h-[280px] md:max-lg:h-[85%] w-full rounded-[21px] pv:max-md:rounded-[5px] object-fill"
                  controls
                  controlsList="nodownload"
                  key={data?.videoURl}
                  onContextMenu={handleRightClick}
                >
                  <source
                    src={data?.videoURl?.replace(
                      "http://34.49.7.176",
                      "https://storage.googleapis.com/why_kid_static_content"
                    )}
                    type="video/mp4"
                  />
                </video>
                <div className="flex items-center gap-5 font-bold mt-3">
                  <p className="text-[60px] md:max-lg:text-[35px] pv:max-md:text-[13px]">
                    {data?.age}
                  </p>
                  <div className="h-[45px] pv:max-md:h-[20px] md:max-lg:h-[35px] w-[2px] bg-[#142548]" />
                  <div className="w-[60%]">
                    <p className="text-left text-[20px] pv:max-md:text-[9px] md:max-lg:text-[22px]">
                      {capitalizeFirstLetter(data?.targetVideo)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[35%]">
                <div className="h-[430px] pv:max-md:h-[110px] lg:max-xl:h-[280px] md:max-lg:h-[85%] bg-[#F9FEFF] lg:max-xl:rounded-[30px] p-5 pv:max-md:p-0 pv:max-md:gap-1 grid grid-cols-2 gap-4">
                  {listGames.map((game, i) => (
                    <div
                      key={i}
                      className="rounded-[25px] pv:max-md:rounded-[5px] md:max-xl:rounded-[16px] overflow-hidden"
                    >
                      <img
                        src={game.thumbnailUrl.replace(
                          "http://34.49.7.176",
                          "https://storage.googleapis.com/why_kid_static_content"
                        )}
                        alt={game.key}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                  {!listGames || listGames.length > 2 ? (
                    <div className=""></div>
                  ) : (
                    <div className="rounded-[6px] overflow-hidden">
                      <div className="h-[207px] w-[63.5px] rounded-[6px] overflow-hidden">
                        <img
                          src={
                            "https://storage.googleapis.com/why_kid_static_content/IMAGES/GAME_THUMBNAIL/transparents-bg.png"
                          }
                          alt={"nogame"}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="h-[207px] w-[63.5px]  rounded-[6px] overflow-hidden">
                        <img
                          src={
                            "https://storage.googleapis.com/why_kid_static_content/IMAGES/GAME_THUMBNAIL/transparents-bg.png"
                          }
                          alt={"nogame"}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <p className="text-center font-bold mt-10 pv:max-md:mt-4 pv:max-md:text-[9px] md:max-lg:text-[15px]  text-[20px]">
                  {/* {capitalizeFirstLetter(data?.titleImg)} */}
                  Hoạt động hỗ trợ
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Close button */}
        <button
          onClick={handleCancel}
          className="absolute -top-5 -right-[50px] pv:max-md:-top-4 pv:max-md:-right-5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 pv:max-md:h-5 pv:max-md:w-5 text-white hover:text-gray-400 font-bold"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default ModalLesson;
