import React, { createContext, useContext, useState } from "react";

export interface UserContextType {
  dataAccountPurchase: {
    email: string;
    packageID: string;
    starTime: string;
    endTime: string;
  };
  setdataAccountPurchase: (newdataAccountPurchase: {
    email: string;
    packageID: string;
    starTime: string;
    endTime: string;
  }) => void;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [dataAccountPurchase, setdataAccountPurchase] = useState<{
    email: string;
    packageID: string;
    starTime: string;
    endTime: string;
  }>({
    email: "email infor",
    packageID: "package id",
    starTime: "",
    endTime: "",
  });

  return (
    <UserContext.Provider
      value={{ dataAccountPurchase, setdataAccountPurchase }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
