import { useState } from "react";
import { useLocation } from "react-router-dom";

import PayWithVNPayQR from "./PayWithVNPayQR";
import PayWithBankCard from "./PayWithBankCard";

const PaymentPolicy = ({ t }: any) => {
  const [payments, setPayments] = useState([
    {
      active: true,
      method: "VN PAY-QR",
      component: <PayWithVNPayQR />,
    },
    {
      active: false,
      method: "Thẻ nội địa và tài khoản ngân hàng",
      component: <PayWithBankCard />,
    },
    {
      active: false,
      method: "Thẻ thanh toán quốc tế",
      component: (
        <div>
          Tương tự như phương thức thanh toán “Ứng dụng thanh toán hỗ trợ
          VNPAY-QR”
        </div>
      ),
    },
    {
      active: false,
      method: "VN PAY E-Wallet",
      component: (
        <div>
          Tương tự như phương thức thanh toán “Ứng dụng thanh toán hỗ trợ
          VNPAY-QR”
        </div>
      ),
    },
  ]);

  const handleOnChangePaymentMethod = (index: number) => {
    for (let i = 0; i < payments.length; i++) {
      if (i == index) {
        payments[index].active = true;
      } else {
        payments[i].active = false;
      }
    }

    setPayments([...payments]);
  };

  return (
    <div className="h-auto w-full flex flex-col gap-14 p-6">
      <div className="w-full flex flex-col lg:flex-row lg:max-xl:justify-between justify-evenly mb-4">
        {payments.map((payment, index) => {
          return (
            <span
              key={index}
              className={`py mb-2 w-fit cursor-pointer text-xl transition-transform duration-200 ease-in-out transform lg:mb-0 lg:hover:scale-110 lg:hover:shadow-lg ${
                payment.active ? "lg:scale-125 border-b-2 shadow-xl" : ""
              }`}
              onClick={() => {
                handleOnChangePaymentMethod(index);
              }}
            >
              {payment.method}
            </span>
          );
        })}
      </div>
      <div className="flex items-center justify-center">
        {payments.map((payment, index) => {
          if (payment.active) {
            return (
              <div
                key={index}
                className="transition-opacity duration-500  animate-fade-in"
              >
                {payment.component}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default PaymentPolicy;
