import { useEffect, useState } from "react";

import Footer from "../../components/Common/footer";

import {
  getAccountInformation,
  getActivePurchaseAccountInfomation,
  getParentInformation,
} from "../../services/userServices/authUser.service";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import SideBar from "../../components/Common/accountProfile/SideBar";
import Banner from "../../components/Common/accountProfile/banner/Banner";
import TransactionHistory from "../../components/Common/accountProfile/TransactionHistory";
import Header from "../../components/Common/header/header";
import SummaryCard from "../../components/Common/accountProfile/SummaryCard";
import { useUserContext } from "../../contexts/userContext";
import { getPackageServiceDetail } from "../../services/servicePackage.service";

const AccountPage = () => {
  const navigate = useNavigate();
  const [priority, setPriority] = useState(0);
  const [startTimestamp, setStartTimestamp] = useState("");
  const [endTimestamp, setEndTimestamp] = useState("");

  const init = async () => {
    const accountSessionStorage = sessionStorage.getItem("accessTokenUser");
    if (!accountSessionStorage) {
      console.warn("accessTokenUser không tồn tại trong sessionStorage.");
      return;
    }

    try {
      const dataAccountPurchase = await getActivePurchaseAccountInfomation(
        accountSessionStorage
      );

      // Check if dataAccountPurchase.data exists and has at least one item
      if (
        dataAccountPurchase &&
        dataAccountPurchase.data &&
        dataAccountPurchase.data.length > 0
      ) {
        const purchaseData = dataAccountPurchase.data[0];
        setStartTimestamp(purchaseData.startTimestamp || "N/A");
        setEndTimestamp(purchaseData.endTimestamp || "N/A");

        const dataPackageServiceDetail = await getPackageServiceDetail(
          purchaseData.productId
        );
        if (dataPackageServiceDetail && dataPackageServiceDetail.data) {
          setPriority(dataPackageServiceDetail.data.priority);
        } else {
          console.error("Package service detail or priority is undefined");
          setPriority(0); // Default fallback value
        }
      } else {
        console.warn("No purchase data available.");
      }

      const data = await getAccountInformation();
      const dataParentInfomation = await getParentInformation();
      setUser((prevUser) => ({
        ...prevUser,
        fullName: dataParentInfomation.data.name,
        email: dataParentInfomation.data.email,
      }));
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  useEffect(() => {
    init();
  }, []);
  const [currentSetting, setCurrentSetting] = useState<{
    id: string;
    title: string;
    subSettings: Array<{ id: string; title: string }>;
  }>({
    id: "-1",
    title: "Default",
    subSettings: [],
  });

  const [user, setUser] = useState<{
    avatarURL: string;
    fullName: string;
    phoneNumber: string;
    email: any;
  }>({
    avatarURL: "",
    fullName: "",
    phoneNumber: "",
    email: "",
  });

  const formatDate = (dateString: number) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  const handleLogout = () => {
    // record logout time
    const logoutTime = Date.now();
    const loginTime = Number(localStorage.getItem("loginTimeUser"));
    const sessionDuration = logoutTime - loginTime;
    // convert Date to hh:mm:ss am/pm
    const hours = Math.floor(sessionDuration / (1000 * 60 * 60));
    const minutes = Math.floor(
      (sessionDuration % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((sessionDuration % (1000 * 60)) / 1000);
    const readableSessionDuration = `${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    // remove 'loginTimeSchool' on localStorage
    localStorage.removeItem("loginTimeUser");
    // ***start integrate firebase analytics***
    logEvent(analytics, "web_session_login_user", {
      duration: readableSessionDuration,
    });
    //remove session
    sessionStorage.removeItem("accessTokenUser");
    sessionStorage.removeItem("refreshTokenUser");
    navigate("/login");
  };
  const transactions:
    | Array<{
        dateTime: string;
        kindOfPackage: string;
      }>
    | [] = [
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
    {
      dateTime: "10/08/2024",
      kindOfPackage: "Gói 3 tháng",
    },
  ];

  // console.log(currentSetting, "passsdata index");

  return (
    // <div className="bg-[#F8F8F8]">
    <div className="bg-[#F8F8F8]">
      <div className="absolute z-50 w-full">
        <Header searchTextChangeColor={true} />
      </div>
      <div className="flex justify-center pv:max-md:px-0">
        <div className="mb-8 w-[85%] lg:max-xl:w-[92%] dvmax:dh:w-[90%] h-fit bg-cover pv:max-ph:pt-24 ph:max-md:pt-36 pt-36">
          <div className="mt-8 lg:grid lg:grid-cols-4 lg:gap-4">
            <SideBar
              user={user}
              currentSetting={currentSetting}
              setCurrentSetting={setCurrentSetting}
            />
            <div className="my-8 col-span-3 lg:mt-0">
              <Banner
                priority={priority}
                dateStart={formatDate(parseInt(startTimestamp))}
                dateEnd={formatDate(parseInt(endTimestamp))}
              />
              {currentSetting.id === "0" && (
                <TransactionHistory transactions={transactions} />
              )}
              {currentSetting.id === "1" && <SummaryCard />}
              {currentSetting.id === "2" && <SummaryCard />}
            </div>

            <div className="bg-red-300 p-4" onClick={handleLogout}>
              đăng xuất
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountPage;
